<template>
  <div id="header-assembly"
    :class="[topHeaderItem.path === '/accessBind' || topHeaderItem.path === '/accessInformation' ||
      topHeaderItem.path === '/versionManage' || topHeaderItem.path === '/addNewsAnnouncement' ||
      topHeaderItem.path === '/template_field' || topHeaderItem.path === '/template_field_configure' ||
      topHeaderItem.path === '/versionDetail' ?
      'hasBack' : '', topHeaderItem.path.includes('knowledge_') || topHeaderItem.path.includes('contract_') || topHeaderItem.path.includes('analysis_') ? 'tab-header-assembly' : 'other-header-assembly']">
    <div class="setup-header-left" v-if="!topHeaderItem.path.includes('analysis_')">
      <span class="setup-header-left-icon" v-if="topHeaderItem.path !== '/accessBind' && topHeaderItem.path !== '/accessInformation' &&
        topHeaderItem.path !== '/versionManage' && topHeaderItem.path !== '/addNewsAnnouncement' &&
        topHeaderItem.path !== '/pluginManage' && topHeaderItem.path !== '/pluginToolManage' && topHeaderItem.path !== '/botConfigure' &&
        topHeaderItem.path !== '/template_field' && topHeaderItem.path !== '/template_field_configure' && topHeaderItem.path !== '/database_configure' &&
        topHeaderItem.path !== '/questionDetail' && topHeaderItem.path !== '/versionDetail'">
        <svgOrIcon :icon="topHeaderItem.icon" :iconClass="'iconfont'" :iconStyle="{ backgroundColor: '#000000' }">
        </svgOrIcon>
      </span>
      <span v-else class="setup-header-back" @click="goBack">
        <i class="iconfont guoran-tongyichicun-18-22-fanhui"></i>
      </span>
      <i v-if="topHeaderItem.path === '/versionManage'"
        class="iconfont iconfont guoran-tongyichicun-zujianzhishiku"></i>
      <div
        :class="['setup-header-left-title', topHeaderItem.path === '/versionManage' ? 'version-header-left' : 'other-header-left']">
        <div class="dirInfo" v-if="$route.path === '/addNewsAnnouncement' && newsStatus">
          <div class="dirNameStatus">
            <i class="iconfont guoran-chajianguanli"></i>{{ newsInfo.name }}
            <span class="newsState"
              :class="newsStatus === 'PUBLISHED' ? 'publishStatus' : (newsStatus === 'UNPUBLISHED' ? 'unPublishStatus' : 'archiveStatus')">
              {{ newsStatus === 'PUBLISHED' ? $t('status.publish') : (newsStatus === 'UNPUBLISHED' ?
                $t('status.unPublish') : $t('status.documentation')) }}
            </span>
          </div>
          <div class="newsDir" v-if="newsInfo.dirName">
            <i class="iconfont guoran-wenjian2"></i>
            {{ newsInfo.dirName.join('/') }}
          </div>
        </div>
        <div class="dirInfo knowledgePage" v-else-if="$route.path === '/versionManage' && knowledgeInfo">
          <div class="dirNameStatus">
            <span class="header-name">{{ topHeaderItem.title }}</span>
            <span class="newsState"
              :class="knowledgeInfo.status === 'PUBLISH' || knowledgeInfo.status === 'PUBLISHING' ? 'publishStatus' :
                (!knowledgeInfo.status || knowledgeInfo.status === 'UNPUBLISHED' ? 'unPublishStatus' : 'archiveStatus')"
              v-if="!knowledgeInfo.previousPubVersion || knowledgeInfo.previousPubVersion.state !== 'PUBLISH'">
              {{ stateKnowledeInfoFilter(knowledgeInfo.status) }}
            </span>
          </div>
          <div class="knowledgeVersion">
            <div class="currentVersion">
              V{{ knowledgeInfo.currentVersion }}
            </div>
            <div class="previousPubVersion"
              v-if="knowledgeInfo.previousPubVersion && knowledgeInfo.previousPubVersion.state === 'PUBLISH'">
              {{ $t('status.publish') }}V{{ knowledgeInfo.previousPubVersion.no }},{{
                $t('versionManage.publishHeaderTip1') }}
              <span class="newVersion" v-if="hasDeleteManagement()" @click="handleCommand('PUBLISHED')">{{
                $t('versionManage.publishHeaderTip2') }}</span>
            </div>
          </div>
        </div>
        <div class="pageLeft" v-else-if="$route.path === '/template_field_configure'">
          <div class="dirInfo">
            <div class="dirNameStatus">
              {{ modelFile.name }}
            </div>
          </div>
          <div class="editPlugin" @click="editInfo">
            <i class="iconfont guoran-tongyichicun-16-15-lianjibianji"></i>
          </div>
        </div>
        <div class="pageLeft" v-else-if="$route.path === '/template_field'">
          <div class="dirInfo">
            <div class="dirNameStatus">
              {{ model.name }}
            </div>
          </div>
          <div class="editPlugin" @click="editInfo">
            <i class="iconfont guoran-tongyichicun-16-15-lianjibianji"></i>
          </div>
        </div>
        <div class="pageLeft" v-else-if="$route.path === '/database_configure'">
          <div class="dirInfo">
            <div class="dirNameStatus">
              {{ chatBased.name }}
            </div>
          </div>
          <div class="editPlugin" @click="editInfo">
            <i class="iconfont guoran-tongyichicun-16-15-lianjibianji"></i>
          </div>
        </div>
        <template v-else-if="$route.path === '/accessInformation'">
          {{ $route.query.channel == 5 ? $t('channel.channelAccess.modeList.cardName1') :
            $t('channel.channelAccess.modeList.cardName2') }}
        </template>
        <template v-else-if="$route.path === '/accessBind'">
          {{ $route.query.channel == 5 ? $t('channel.accessBind.notAccess.FeishuBindCom') :
            $t('channel.accessBind.notAccess.YuqueBindCom') }}
        </template>
        <template v-else-if="$route.path === '/versionManage'">
          {{ topHeaderItem.title }}
        </template>
        <template v-else> {{ $t('topTabBar.' + topHeaderItem.title) }} </template>
      </div>
    </div>
    <div class="setup-header-left" v-else>
      <div class="tabList">
        <div class="tabItem" v-for="(item, index) in tabBar" :key="index"
          :class="botTabActive === item.path ? 'activeTab' : 'unActiveTab'" @click="checkTab(item)">
          <span class="tabIcon">
            <i :class="item.iconfont"></i>
          </span>
          <span>{{ $t('topTabBar.' + item.name) }}</span>
        </div>
      </div>
    </div>
    <div class="setup-header-right">
      <div class="setup-header-right-l" v-if="$route.path === '/addNewsAnnouncement'">
        <template v-if="newsStatus">
          <div class="popoverBtn saveBtn" v-if="pageStatus === 'edit'" @click="newsFun('save')">
            <i class="iconfont guoran-tongyichicun-16-03-baocun"></i>
            {{ $t('common.save') }}
          </div>
          <el-popover placement="bottom" width="100" popper-class="commitLoadMore" trigger="hover" v-model="visible">
            <div class="popoverContainer">
              <div class="popoverItem" @click="handleCommand('read')">
                <i class="iconfont guoran-tongyichicun-chakan"></i>
                {{ $t('common.read') }}
              </div>
              <div class="popoverItem" @click="handleCommand('edit')">
                <i class="iconfont guoran-a-18_huaban1fuben3"></i>
                {{ $t('common.edit') }}
              </div>
            </div>
            <div slot="reference" class="popoverBtn readBtn">
              <template v-if="pageStatus === 'read'">
                <i class="iconfont guoran-tongyichicun-chakan"></i>
                <span class="stateName">{{ $t('common.read') }}</span>
              </template>
              <template v-else>
                <i class="iconfont guoran-a-18_huaban1fuben3"></i>
                <span class="stateName">{{ $t('common.edit') }}</span>
              </template>
              <i class="el-icon-arrow-down"></i>
            </div>
          </el-popover>
          <template v-if="newsStatus === 'UNPUBLISHED'">
            <el-popover placement="bottom" width="100" popper-class="commitLoadMore" trigger="hover" v-model="visible2">
              <div class="popoverContainer">
                <div class="popoverItem" @click="handleCommand('PUBLISHED')">
                  <i class="iconfont guoran-fabu"></i>
                  {{ $t('status.release') }}
                </div>
                <div class="popoverItem" @click="handleCommand('ARCHIVE')">
                  <i class="iconfont guoran-tongyichicun-yituguidangxiang1"></i>
                  {{ $t('status.documentation') }}
                </div>
              </div>
              <div slot="reference" class="popoverBtn publishBtn">
                <i class="iconfont guoran-fabu"></i>
                <span class="stateName">{{ $t('status.release') }}</span>
                <i class="el-icon-arrow-down"></i>
              </div>
            </el-popover>
          </template>
          <template v-else-if="newsStatus === 'PUBLISHED'">
            <el-popover placement="bottom" width="100" popper-class="commitLoadMore" trigger="hover" v-model="visible2">
              <div class="popoverContainer">
                <div class="popoverItem" @click="handleCommand('UNPUBLISHED')">
                  <i class="iconfont guoran-a-16-09"></i>
                  {{ $t('status.cancelPublish') }}
                </div>
                <div class="popoverItem" @click="handleCommand('ARCHIVE')">
                  <i class="iconfont guoran-tongyichicun-yituguidangxiang1"></i>
                  {{ $t('status.documentation') }}
                </div>
              </div>
              <div slot="reference" class="popoverBtn publishBtn">
                <i class="iconfont guoran-a-16-09"></i>
                <span class="stateName">{{ $t('status.cancelPublish') }}</span>
                <i class="el-icon-arrow-down"></i>
              </div>
            </el-popover>
          </template>
          <template v-else-if="newsStatus === 'ARCHIVE'">
            <el-popover placement="bottom" width="100" popper-class="commitLoadMore" trigger="hover" v-model="visible2">
              <div class="popoverContainer">
                <div class="popoverItem" @click="handleCommand('UNARCHIVE')">
                  <i class="iconfont guoran-a-16-09"></i>
                  {{ $t('status.cancelDocumentation') }}
                </div>
                <div class="popoverItem" @click="handleCommand('PUBLISHED')">
                  <i class="iconfont guoran-fabu"></i>
                  {{ $t('status.release') }}
                </div>
              </div>
              <div slot="reference" class="popoverBtn publishBtn">
                <i class="iconfont guoran-fabu"></i>
                {{ $t('status.cancelDocumentation') }}
                <i class="el-icon-arrow-down"></i>
              </div>
            </el-popover>
          </template>
          <el-popover placement="top-start" width="100" trigger="click" popper-class="commitLoadMore"
            v-model="deleteVisible">
            <div class="popoverItem" @click="newsFun('delete')">
              <i class="iconfont guoran-piliangshanchu"></i>
              {{ $t('common.delete') }}
            </div>
            <i slot="reference" class="iconfont guoran-qita1"></i>
          </el-popover>
        </template>
        <template v-else>
          <div class="popoverBtn saveBtn" @click="newsFun('save')">
            <i class="iconfont guoran-tongyichicun-16-03-baocun"></i>
            {{ $t('common.save') }}
          </div>
          <div class="popoverBtn" @click="newsFun('publish')">
            <i class="iconfont guoran-fabu"></i>
            {{ $t('status.release') }}
          </div>
        </template>
      </div>
      <div class="setup-header-right-l"
        v-else-if="$route.path === '/botConfigure' || $route.path === '/template_field'">
        <el-popover placement="top-start" width="100" trigger="click" popper-class="commitLoadMore"
          v-model="deleteVisible">
          <div class="popoverItem" @click="newsFun('delete')">
            <i class="iconfont guoran-piliangshanchu"></i>
            {{ $t('common.delete') }}
          </div>
          <i slot="reference" class="iconfont guoran-qita1"></i>
        </el-popover>
      </div>
      <div class="setup-header-right-l" v-else-if="$route.path === '/versionManage' && !openSubsection">
        <div class="downLoadUrl" @click="downLoad" v-if="downLoadBtn">
          <span>{{ $t('common.download') }}</span>
        </div>
        <template v-if="isReadStatus">
          <div class="summaryBtn" v-show="!main_isLishi" :class="showSummary ? 'summaryActiveBtn' : ''" @click="summaryFun">
            <i class="iconfont guoran-tongyichicun-write-29-jiqiren"></i>
            <span>{{ $t('versionManage.intelligentSummary') }}</span>
          </div>
          <el-popover placement="bottom" :width="$i18n.locale === 'en' ? '300' : '220'" popper-class="commitLoadMore"
            trigger="hover" v-model="editVisible" v-if="!_isLiBang()">
            <div class="popoverContainer">
              <div class="popoverItem" @click="showEditFun('previous')"
                v-if="previousEditVersion && (previousEditVersion.state && (previousEditVersion.state !== 'PUBLISH' && (!knowledgeInfo.pendingApproval && !approvalLoading[$route.query.id] && !approvalStatus[$route.query.id])))">
                <i class="iconfont guoran-tongyichicun-write-01-bianji"></i>
                {{ $t('versionManage.editPreviousVersion') }}
                <span style="margin-left: 4px">
                  (V{{ previousEditVersion.version }})
                </span>
              </div>
              <div class="popoverItem" @click="showEditFun('copyNewVersion')">
                <i class="iconfont guoran-fuzhi"></i>
                {{ $t('versionManage.copyVersion') }}
              </div>
              <template
                v-if="knowledgeInfo.status !== 'PUBLISH' && knowledgeInfo.status !== 'PUBLISHING' && (!knowledgeInfo.pendingApproval && !approvalLoading[$route.query.id] && !approvalStatus[$route.query.id])">
                <div class="popoverItem" @click="showEditFun('current')">
                  <i class="iconfont guoran-tongyichicun-write-01-bianji"></i>
                  {{ $t('versionManage.editCurrentVersion') }}
                  <span style="margin-left: 4px">
                    (V{{ knowledgeInfo.currentVersion }})
                  </span>
                </div>
              </template>
            </div>
            <div slot="reference" class="summaryBtn" v-show="hasEditManagement()">
              <i class="iconfont guoran-tongyichicun-18-31-rengongluru"></i>
              <span>{{ $t('versionManage.editButton') }}</span>
              <i style="margin-right: 0;" class="el-icon-arrow-down"></i>
            </div>
          </el-popover>
          <template v-if="hasDeleteManagement()">
            <div v-if="approvalLoading[$route.query.id]" class="popoverBtn publishBtn">
              <div class="generate_loading"></div>
              <span class="stateName">请稍后</span>
            </div>
            <div v-if="approvalStatus[$route.query.id]" class="popoverBtn publishBtn">
              <i class="generate_loading"></i>
              <span class="stateName">请确认</span>
            </div>
            <template
              v-if="knowledgeInfo.pendingApproval && !approvalLoading[$route.query.id] && !approvalStatus[$route.query.id]">
              <div class="popoverBtn publishBtn" @click="handleCommand('PENDING_APPROVAL')">
                <i class="iconfont guoran-gaizhangshenpi"></i>
                <span class="stateName">审批中</span>
              </div>
            </template>
            <template
              v-if="!knowledgeInfo.pendingApproval && !approvalLoading[$route.query.id] && !approvalStatus[$route.query.id]">
              <template v-if="!knowledgeInfo.status || knowledgeInfo.status === 'UNPUBLISHED'">
                <el-popover placement="bottom" width="100" popper-class="commitLoadMore" trigger="hover"
                  v-model="visible2">
                  <div class="popoverContainer">
                    <div class="popoverItem" @click="handleCommand('PUBLISHED')">
                      <i class="iconfont guoran-fabu"></i>
                      {{ $t('status.release') }}
                    </div>
                    <div class="popoverItem" @click="handleCommand('UNPUBLISHED')"
                      v-if="knowledgeInfo.previousPubVersion && knowledgeInfo.previousPubVersion.state === 'PUBLISH'">
                      <i class="iconfont guoran-a-16-09"></i>
                      {{ $t('status.cancelPublish') }}
                    </div>
                    <div class="popoverItem" @click="handleCommand('ARCHIVE')">
                      <i class="iconfont guoran-tongyichicun-yituguidangxiang1"></i>
                      {{ $t('status.documentation') }}
                    </div>
                  </div>
                  <div slot="reference" class="popoverBtn publishBtn" @click="handleCommand('PUBLISHED')">
                    <i class="iconfont guoran-fabu"></i>
                    <span class="stateName">{{ $t('status.release') }}</span>
                    <i class="el-icon-arrow-down"></i>
                  </div>
                </el-popover>
              </template>
              <template v-else-if="knowledgeInfo.status === 'PUBLISH' || knowledgeInfo.status === 'PUBLISHING'">
                <el-popover placement="bottom" width="120" popper-class="commitLoadMore" trigger="hover"
                  v-model="visible2">
                  <div class="popoverContainer">
                    <div class="popoverItem" @click="handleCommand('UNPUBLISHED')">
                      <i class="iconfont guoran-a-16-09"></i>
                      {{ $t('status.cancelPublish') }}
                    </div>
                    <div class="popoverItem" @click="handleCommand('ARCHIVE')">
                      <i class="iconfont guoran-tongyichicun-yituguidangxiang1"></i>
                      {{ $t('status.documentation') }}
                    </div>
                  </div>
                  <div slot="reference" class="popoverBtn publishBtn" @click="handleCommand('UNPUBLISHED')">
                    <i class="iconfont guoran-a-16-09"></i>
                    <span class="stateName">{{ $t('status.cancelPublish') }}</span>
                    <i style="margin-right: 0;" class="el-icon-arrow-down"></i>
                  </div>
                </el-popover>
              </template>
              <template v-else>
                <el-popover placement="bottom" width="120" popper-class="commitLoadMore" trigger="hover"
                  v-model="visible2">
                  <div class="popoverContainer">
                    <div class="popoverItem" @click="handleCommand('UNARCHIVE')">
                      <i class="iconfont guoran-a-16-09"></i>
                      {{ $t('status.cancelDocumentation') }}
                    </div>
                    <div class="popoverItem" @click="handleCommand('PUBLISHED')">
                      <i class="iconfont guoran-fabu"></i>
                      {{ $t('status.release') }}
                    </div>
                  </div>
                  <div slot="reference" class="popoverBtn publishBtn" @click="handleCommand('UNARCHIVE')">
                    <i class="iconfont guoran-fabu"></i>
                    {{ $t('status.cancelDocumentation') }}
                    <i style="margin-right: 0;" class="el-icon-arrow-down"></i>
                  </div>
                </el-popover>
              </template>
            </template>
          </template>
          <el-popover placement="top-start" width="100" trigger="click" popper-class="commitLoadMore"
            v-model="deleteVisible">
            <div class="popoverItem" @click="newsFun('delete')">
              <i class="iconfont guoran-piliangshanchu"></i>
              {{ $t('common.delete') }}
            </div>
            <i slot="reference" class="iconfont guoran-qita1" v-show="hasDeleteManagement()"></i>
          </el-popover>
        </template>
        <template v-else>
          <div class="summaryBtn" @click="showLookFun">
            <i class="iconfont guoran-chakan"></i>
            <span>查看</span>
          </div>
        </template>
      </div>
      <div class="setup-header-right-l" v-else-if="$route.path === '/automation'">
        <div class="sortBtn" @click="adjustPriority">
          <span>
            <i v-if="automationSort" class="el-icon-check"></i>
            <i v-if="!automationSort" class="iconfont guoran-a-16-10"></i>
            {{ automationSort ? '完成' : '调整优先级' }}
          </span>
        </div>
        <div class="automationBtn" @click="addAutomation">
          <span><i class="el-icon-plus"></i>添加自动化流程</span>
        </div>
      </div>
      <div class="setup-header-right-r">
        <el-select v-model="mainValue" placeholder="请选择" size="small" @change="changeCompany" style="margin-right: 20px"
          v-if="false">
          <el-option v-for="item in mainOptions" :key="item.corpId" :label="item.corpName" :value="item.corpId">
            <img height="12px" v-if="item.corpType == 0"
              src="https://static.guoranbot.com/cdn-arsenal/dev/img/qiyechat.b7289a96.png" alt="" srcset="" />
            <img height="12px" v-if="item.corpType == 3"
              src="https://static.guoranbot.com/cdn-arsenal/dev/img/dingding.874450b3.jpeg" alt="" srcset="" />
            <span style=" display: inline-block;
                 margin-left: 6px;
                 color: #8492a6;
                  font-size: 13px;">{{ item.corpName }}</span>
          </el-option>
        </el-select>
        <div class="help-document" v-if="false">
          <el-tooltip class="help-item" effect="dark" placement="bottom">
            <div slot="content">
              <span>上传下载中心</span>
            </div>
            <el-link type="primary" class="help-link" :underline="false" @click="gotoUpDown">
              <el-badge class="item" style="display:flex">
                <i class="iconfont guoran-a-24-02"></i>
              </el-badge>
            </el-link>
          </el-tooltip>
        </div>
        <el-dropdown @command="topHandleCommand">
          <span class="el-dropdown-link">
            <span class="head_portrait" style="display:flex">
              <el-avatar :size="37" :src="userInfo.profilePhoto"></el-avatar>
            </span>
          </span>
          <el-dropdown-menu slot="dropdown" style="min-width:150px;">
            <el-dropdown-item
              style="color: black;word-break: break-word;border-bottom: 2px solid #f5f8fb;line-height: 30px">
              {{ userInfo.realName ? userInfo.realName : userInfo.username ? userInfo.username : ''
              }}</el-dropdown-item>
            <el-dropdown-item command="profile" class="borderBottom">
              <i class="el-icon-user-solid" style="font-size: 14px;"></i>{{ $t('common.personalInformation') }}
            </el-dropdown-item>
            <el-dropdown-item command="business" class="borderBottom" v-if="roles === '1' || roles === '6'">
              <i class="el-icon-office-building" style="font-size: 14px;"></i>{{ $t('common.businessManagement') }}
            </el-dropdown-item>
            <!-- <el-dropdown-item command="change" class="borderBottom">
              <i class="arsenal_icon arsenalzhuanjie" style="font-size: 14px;"></i>切换系统
            </el-dropdown-item> -->
            <el-dropdown-item command="logout">
              <i class="el-icon-switch-button" style="font-size: 14px;"></i>{{ $t('common.logOut') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import svgOrIcon from "./svgOrIcon.vue";
import { redirect2LoginPage } from "@/http/redirectUri";
import { handlerChannel } from '@/utils/tools.js'
import { mapGetters, mapActions, mapState } from 'vuex'
// import common from "@/utils/common";
export default {
  name: "top-header",
  data() {
    return {
      userInfo: {
        profilePhoto:
          "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
      },
      roles: "",
      deriveScreen: false,
      showContentToop: false,
      showContent: false,
      tooltipContent: "上传下载中心",
      refMainOrSeond: '',
      mainOptions: [],
      mainValue: "",
      visible: false,
      visible2: false,
      editVisible: false,
      deleteVisible: false,
      tabBar: [],
      analysisList: [
        {
          name: "知识总览",
          iconfont: "iconfont guoran-zonglan",
          path: "/analysis_knowledgeOverview"
        },
        {
          name: "知识分析",
          iconfont: "iconfont guoran-tongyichicun-zhishiku",
          path: "/analysis_KnowledgeAnalysis"
        },
      ],
      automationList: [
        {
          name: '自动化流程',
          iconfont: 'iconfont guoran-tongyichicun-qudaoguanli',
          path: "/knowledge_automation"
        }
      ],
      botTabActive: "",
      showSummary: true,
      showEdit: false,
      automationSort: false,
      approvalLoading: {},
      approvalStatus: {}
    };
  },
  props: ["circleUrl", "upload"],
  components: {
    svgOrIcon,
  },
  mounted() {
    this.getCompany();
    this.$nextTick(() => {
      let userInfo = sessionStorage.getItem("userInfo");
      if (userInfo && userInfo !== "{}") {
        this.userInfo = JSON.parse(userInfo);
      }
    })
  },
  computed: {
    topHeaderItem() {
      let atPresentLink = {
        title: this.$route.query.channel ? this.$route.query.id ? handlerChannel(Number(this.$route.query.channel)) : '接入' + handlerChannel(Number(this.$route.query.channel)) : this.$route.meta.title,
        icon: this.$route.meta.icon,
        path: this.$route.path
      }
      if (this.$route.path === '/versionManage') {
        atPresentLink.title = this.$route.query.name;
      }
      return atPresentLink;
    },
    ...mapGetters('news', {
      newsStatus: 'getNewsStatus'
    }),
    ...mapGetters('news', {
      newsName: 'getNewsName'
    }),
    ...mapGetters('news', {
      pageStatus: 'getPageStatus'
    }),
    ...mapGetters('plugin', {
      pluginInfo: 'getPluginInfo'
    }),
    ...mapGetters('plugin', {
      pluginToolInfo: 'getPluginToolInfo'
    }),
    ...mapGetters('botConfig', {
      botConfig: 'getBot'
    }),
    ...mapGetters('botConfig', {
      isSaving: 'getSaveStatus'
    }),
    ...mapGetters('plugin', {
      pluginToolEdit: 'getPluginToolEditStatus'
    }),
    ...mapGetters('knowledge', {
      knowledgeInfo: 'getKnowledge'
    }),
    ...mapGetters('modelFile', {
      modelFile: 'getModelFile'
    }),
    ...mapGetters('modelFile', {
      model: 'getModel'
    }),
    ...mapState({
      // 智能写作次数/容量 套餐使用信息
      newsInfo: state => state.news.newsInfo
    }),
    ...mapGetters('knowledge', {
      downLoadBtn: 'getDownLoadBtn'
    }),
    ...mapGetters('botConfig', {
      botVersion: 'getBotVersion'
    }),
    ...mapGetters('chatBased', {
      chatBased: 'getChatBased'
    }),
    ...mapGetters('knowledge', {
      isReadStatus: "getDocumentStatus"
    }),
    ...mapGetters('knowledge', {
      previousEditVersion: "getPreviousEditVersion"
    }),
    ...mapGetters('knowledge', {
      openSubsection: "getOpenSubsection"
    }),
  },
  watch: {
    '$route': {
      handler(value) {
        if (value) {
          if (value.path.includes('analysis_')) {
            this.tabBar = this.analysisList;
          }
          this.botTabActive = value.path
          console.debug('$route', value, this.tabBar)
        }
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    getApprovalLoading(boolean) {
      this.$set(this.approvalLoading, this.$route.query.id, boolean)
    },
    sureApprovalStatus(boolean) {
      this.$set(this.approvalStatus, this.$route.query.id, boolean)
    },
    saveApiPluginInfo() {
      this.$emit("saveApiPluginInfo");
    },
    addAutomation() {
      this.$emit('addAutomation')
    },
    adjustPriority() {
      this.automationSort = !this.automationSort
      this.$emit('adjustPriority', this.automationSort)
    },
    topHandleCommand(command) {
      if (command === "logout") {
        this.$http.delete("/portal-api/sso/auth/sign-out").then(res => {
          if (res.status === 200) {
            redirect2LoginPage();
            // window.localStorage.clear();
            // window.sessionStorage.clear();
          } else {
            this.$message.error(res.message);
          }
        });
        // localStorage.clear()
      }
      if (command === 'profile') {
        this.$router.push({ path: "/personal" });
      }
    },
    gotoUpDown() {

    },
    getCompany() {
      let uid = sessionStorage.getItem("_uid");
      let url = "/portal-api/department/corps4main?userId=" + uid;
      this.$http.get(url).then((res) => {
        if (res.data.code == '0') {
          let _corpId = sessionStorage.getItem('_corpId')
          console.log(_corpId, '_corpId_corpId_corpId');
          this.mainOptions = res.data.data;
          this.mainValue = _corpId ? _corpId : this.mainOptions[0] ? this.mainOptions[0].corpId : "";
          let name = this.mainOptions[0] ? this.mainOptions[0].corpName : "";
          if (name) {
            sessionStorage.setItem('companyName', name)
          }
          sessionStorage.setItem('_corpId', this.mainValue);
          this.$emit('getMainVal', this.mainValue)
        }
      })
    },
    changeCompany(id) {
      for (let i = 0; i < this.mainOptions.length; i++) {
        if (id === this.mainOptions[i].id) {
          sessionStorage.setItem('companyName', this.mainOptions[i].corpName);
          sessionStorage.setItem('isWeChat', this.mainOptions[i].corpType == 0 && this.mainOptions[i].authType == 0 ? 'true' : 'false')
        }
      }

      sessionStorage.setItem('_corpId', id);
      this.$emit('changeCompany')
    },
    goBack() {
      this.updateNewsStatus('');
      this.updateNewsName({
        name: "",
        dirName: ""
      });
      this.updateNewsPageStatus('');
      if (this.$route.name === 'versionManage') {
        this.$emit('goBack')
      } else {
        this.$router.go(-1)
      }
    },
    getUserInfo() {
      let uid = sessionStorage.getItem("_uid");
      this.$http.get("/portal-api/account/appoint/" + uid).then(res => {
        if (res.data.code === "0") {
          sessionStorage.setItem("userInfo", JSON.stringify(res.data.data));
          this.userInfo = res.data.data;
        }
      });
    },
    ...mapActions('news', [
      'updateNewsPageStatus'
    ]),
    ...mapActions('news', [
      'updateNewsStatus'
    ]),
    ...mapActions('news', [
      'updateNewsName'
    ]),
    ...mapActions('knowledge', [
      'updateDocumentStatus'
    ]),
    handleCommand(command) {
      this.visible = false;
      this.visible2 = false;
      if (command === 'edit' && this.newsStatus === 'PUBLISHED') {
        this.$message({
          message: "新闻已经发布，请先取消发布后再进行编辑",
          type: "warning"
        })
      } else if (command === 'read' || command === 'edit') {
        this.updateNewsPageStatus(command)
      } else {
        this.newsFun(command)
      }
    },
    newsFun(type, from) {
      this.deleteVisible = false;
      this.$emit('newsFun', type, from)
    },
    addPlugin() {
      this.$emit('addPlugin')
    },
    editPlugin() {
      this.$emit('editPlugin')
    },
    editPluginTool() {
      this.$emit('editPlugin')
    },
    editBot() {
      this.$emit('editBot')
    },
    editInfo() {
      this.$emit('editInfo')
    },
    checkTab(item) {
      this.botTabActive = item.path;
      this.$router.push({
        path: item.path
      })
    },
    summaryFun() {
      this.showSummary = !this.showSummary;
      this.$emit('summaryFun', this.showSummary)
    },
    showEditFun(type) {
      console.log("showEditFun-type:", type)
      this.editVisible = false;
      this.$emit('showEditFun', type)
    },
    showLookFun() {
      this.updateDocumentStatus(true);
      this.$emit('changeReadStatus')
    },
    hasEditManagement() {
      let bool = false;
      let fileSuffix = this.$route.query.fileSuffix;
      //只有以下几种类型可以进行编辑
      if (fileSuffix === 'ppt' || fileSuffix === 'pptx' || fileSuffix === 'xls' || fileSuffix === 'xlsx' ||
        fileSuffix === 'pdf' || fileSuffix === 'doc' || fileSuffix === 'docx' || fileSuffix === 'txt' || fileSuffix === 'ofd') {
        bool = true;
      }
      if (!bool) {
        return
      }
      let roleId = sessionStorage.getItem('roleId')
      if (roleId == 1) {
        return true
      }
      return this.checkPermission(this.knowledgeInfo);
    },
    hasDeleteManagement() {
      // let bool = false;
      let roleId = sessionStorage.getItem('roleId')
      if (roleId == 1) {
        return true
      }
      return this.checkPermission(this.knowledgeInfo);
    },
    // 校验权限，批量发布时，有编辑 || 管理权限 才能操作
    checkPermission(item) {
      let flag = false
      // 整体的权限列表 ，包含部门 和 个人 ， tyoe 1 代表 部门 ， 0 代表 个人
      let managePermission = item.managePermission ? item.managePermission : []
      let editPermission = item.editPermission ? item.editPermission : []
      let accountGroupIds = sessionStorage.getItem("_groupIds") ? JSON.parse(sessionStorage.getItem("_groupIds")) : []
      let accountUserId = sessionStorage.getItem("_uid") * 1
      if (editPermission.includes(accountUserId) || managePermission.includes(accountUserId)) {
        flag = true
        return flag
      }
      for (let index = 0; index < accountGroupIds.length; index++) {
        const gIds = accountGroupIds[index] * 1;
        if (editPermission.includes(gIds) || managePermission.includes(gIds)) {
          flag = true
          return flag
        }
      }
      return flag
    },
    downLoad() {
      this.$emit('downLoadUrl')
    },
    stateKnowledeInfoFilter(status) {
      if (!status || status == 'UNPUBLISHED') {
        return this.$t('status.unPublish');
      } else if (status == 'PUBLISH' || status == 'PUBLISHING') {
        return this.$t('status.publish')
      } else {
        return this.$t('status.documentation')
      }
    }
  }
};
</script>

<style scoped lang="less">
#header-assembly {
  background-color: #ffffff;

  box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  width: calc(100% - 30px);
  padding: 0 15px;

  .setup-header-left {
    color: #000000;
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 64px;

    .tabList {
      display: flex;
      align-items: center;

      .tabItem {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        cursor: pointer;

        .tabIcon {
          flex: none;
          width: 21px;
          height: 21px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 6px;

          i {
            font-size: 12px;
          }
        }
      }

      .activeTab {
        color: #366aff;
        border-bottom: 2px solid #366aff;

        .tabIcon {
          background: #366AFF;
          color: #ffffff;
        }
      }

      .unActiveTab {
        color: #000000;
        border-bottom: 2px solid #ffffff;

        .tabIcon {
          background: #ECEFF5;
          color: #999999;
        }
      }
    }

    .setup-header-left-icon {
      margin-right: 9px;
      display: flex;
      align-items: center;

      i {
        font-size: 16px;
      }

      .guoran-chajianguanli {
        font-size: 18px;
      }
    }

    .setup-header-left-title {
      font-size: 16px;
      margin-left: 4px;
      max-width: 600px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .header-name {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .dirInfo {
        max-width: 300px;

        .dirNameStatus {
          display: flex;
          align-items: center;

          // overflow: hidden;
          // text-overflow: ellipsis;
          // white-space: nowrap;
          .guoran-tongyichicun-write-29-jiqiren2 {
            font-size: 18px !important;
          }

          i {
            margin-right: 6px;
            font-size: 14px;
          }

          .guoran-chajianguanli {
            font-size: 18px !important;
          }

          .newsState {
            flex: none;
            //width: 44px;
            padding: 0 4px;
            height: 18px;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 6px;
            border-radius: 2px;
            margin-left: 6px;
          }

          .unPublishStatus {
            border: 1px solid #FFE0CF;
            color: #FF8C4C;
            background: #FFF1E9;
          }

          .publishStatus {
            color: #00C2BB;
            background: #E1FAF9;
            border: 1px solid #B0E7E4;
          }

          .pluginPublishStatus {
            color: #00C2BB;
            background: #E1FAF9;
            border: 1px solid #B0E7E4;
          }

          .pluginUnPublishStatus {
            border: 1px solid #FFE0CF;
            color: #FF8C4C;
            background: #FFF1E9;
          }

          .archiveStatus {
            color: #9DA5BF;
            background: #F3F3F3;
            border: 1px solid #DDE0E9;
          }

          .pluginType {
            flex: none;
            padding: 0 8px;
            height: 18px;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 6px;
            border-radius: 10px;
            margin-left: 6px;
          }

          .KnowledgeBasedBtn {
            background: #E9F9EC;
            border: 1px solid #4BE267;
            color: #0AC02C;
          }

          .ApiBasedBtn {
            background: #ECF1FF;
            border: 1px solid #A1B9FF;
            color: #366AFF;
          }

          .DataBaseBtn {
            border: 1px solid #aca3f5;
            background: #EEECFD;
            color: #766BF0;
          }

          .botConfig_name {
            max-width: 240px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .newsDir {
          color: #A9B3C6;
          font-size: 12px;
          margin-top: 4px;
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          i {
            font-size: 12px;
            color: #A9B3C6;
            margin-right: 4px;
          }
        }
      }

      .knowledgePage {
        display: flex;
        align-items: center;
        max-width: 60%;

        .knowledgeVersion {
          display: flex;
          align-items: center;
          margin-left: 10px;

          .currentVersion {
            background-color: #ECF1FF;
            height: 26px;
            width: 26px;
            border-radius: 50%;
            color: #366aff;
            font-size: 12px;
            z-index: 99;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .previousPubVersion {
            background-color: #EEF2FF;
            border-radius: 16px;
            color: #A9B3C6;
            font-size: 12px;
            height: 20px;
            padding-left: 20px;
            padding-right: 12px;
            margin-left: -10px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .newVersion {
            color: #366aff;
            font-size: 12px;
            cursor: pointer;
            padding-left: 8px;
          }
        }
      }

      .pageLeft {
        display: flex;
        align-items: center;

        .editPlugin {
          margin-left: 10px;
          cursor: pointer;
          width: 24px;
          height: 24px;
          background: #EDF2FF;
          border-radius: 5px;
          border: 1px solid #A1B9FF;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            color: #366aff;
            font-size: 12px;
          }
        }

        .version {
          margin-left: 8px;

          i {
            color: #a1b9ff;
            margin-right: 4px;
          }
        }
      }

      .editInfo {
        display: flex;
        align-items: center;

        i {
          margin-left: 10px;
          cursor: pointer;
          width: 24px;
          height: 24px;
          background: #EDF2FF;
          border-radius: 5px;
          border: 1px solid #A1B9FF;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #366aff;
          font-size: 12px;
        }
      }
    }

    .other-header-left {
      max-width: 400px;
    }

    .setup-header-back {
      width: 60px;
      height: 62px;
      border-radius: 5px;
      background: #AAB9E1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 9px;
      color: #FFFFFF;
      cursor: pointer;

    }

    .iconfont {
      //margin-right: 4px;
    }
  }


  .setup-header-right {
    padding-right: 15px;
    display: flex;
    align-items: center;

    .setup-header-right-l {
      padding-right: 20px;
      display: flex;
      align-items: center;

      .popoverBtn {
        flex: none;
        width: 71px;
        height: 28px;
        background: #FFFFFF;
        border: 1px solid #A1B9FF;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #366AFF;
        box-sizing: border-box;
        i {
          padding-right: 4px;
          font-size: 14px;
        }

        .el-icon-arrow-down {
          padding-right: 0;
        }
      }


      .saveBtn {
        margin-right: 14px;
        background-color: #366aff !important;
        color: #ffffff;
        border: none !important;
        height: 32px !important;
      }

      .addPlugin {
        margin-right: 14px;
        background-color: #366aff !important;
        color: #ffffff;
        border: none !important;
        height: 32px !important;
        width: auto;
        padding: 0 10px;
      }

      .readBtn {
        margin-right: 14px;
        width: 90px !important;

        .stateName {
          padding: 0 4px;
        }
      }

      .publishBtn {
        width: auto;
        padding: 0 10px;

        .stateName {
          padding: 0 4px;
        }
      }

      .guoran-qita1 {
        cursor: pointer;
        color: #366aff;
        margin-left: 14px;
        font-weight: 600;
        font-size: 18px;
      }

      .summaryBtn {
        flex: none;
        //width: 95px;
        padding: 0 6px;
        height: 28px;
        background: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border: 1px solid #a1b9ff;
        border-radius: 15px;
        cursor: pointer;
        color: #366aff;
        margin-right: 10px;

        i {
          font-size: 14px;
          margin: 0 5px;
        }

        .el-icon-arrow-down {
          font-size: 14px;
        }
      }

      .summaryActiveBtn {
        background: #366aff !important;
        color: #ffffff !important;
      }

      .downLoadUrl {
        flex: none;
        height: 32px;
        width: 70px;
        background: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border-radius: 15px;
        cursor: pointer;
        color: #366aff;
        border: 1px solid #a1b9ff;
        margin-right: 10px;
      }

      .automationBtn {
        flex: none;
        height: 32px;
        padding: 0 10px;
        background: #366aff;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border-radius: 15px;
        cursor: pointer;
        color: #FFFFFF;
        margin-right: 10px;

        i {
          font-size: 14px;
        }
      }

      .sortBtn {
        flex: none;
        height: 32px;
        padding: 0 10px;
        background: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border: 1px solid #a1b9ff;
        border-radius: 15px;
        cursor: pointer;
        color: #366aff;
        margin-right: 10px;

        i {
          font-size: 14px;
        }
      }
    }

    .setup-header-right-r {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .iconfont {
        font-size: 24px;
        padding-right: 20px;
        color: #404040;
      }

      /deep/.el-badge__content {
        width: 17px;
        height: 17px;
        border-radius: 50%;
        background-color: #366aff;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      /deep/.el-badge__content.is-fixed {
        right: 0;
        left: -6px;
      }
    }

    .statistics-btnView {
      display: flex;
      align-items: center;
      flex: none;

      .input-divs {
        width: 172px;
        height: 32px;
        background: #fff;
        border: 1px solid #dcdfe6;
        border-radius: 5px;
        line-height: 32px;
        padding: 0 12px;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-right: 10px;

        .placeholder-text {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #606266;
          display: flex;
          align-items: center;
          // justify-content: center;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .el-icon-arrow-down {
          font-size: 16px;
          color: #606266;
          margin-left: 4px;
          margin-right: 0;
        }
      }
    }

    #selectStaff {
      width: 172px;
    }

    .notice_badge {
      cursor: pointer;

      /deep/ .el-badge__content {
        background: red;
      }
    }

    /*.guoran-a-16-13{
            font-size: 14px;
        }*/
  }

  .acceptor-status-view {
    width: 110px;
    min-width: 110px;
    height: 34px;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    border: 1px solid;
    // background: #dcdfe6;
    cursor: pointer;
    margin-right: 10px;
    box-sizing: border-box;
    padding: 0 15px;

    i {
      padding-right: 8px !important;
      font-size: 14px !important;
      color: inherit !important;
    }
  }

}

.generate_loading {
  display: inline-block;
  padding: 2px;
  border-radius: 3px;
  padding-left: 20px;
  position: relative;
  font-size: 12px;
  -webkit-user-modify: read-only;
  -moz-user-modify: read-only;
  display: flex;
  align-items: center;
  height: 32px;
}

.generate_loading::after {
  content: '';
  background-image: url(../assets/images/building.png);
  background-size: 100%;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 8px;
  left: 2px;
  color: #366aff;
  -webkit-animation: rotate 1.5s linear infinite;
  animation: rotate 1.5s linear infinite;
}

.tab-header-assembly {
  .tabList {
    .tabItem {
      padding: 20px 0;
    }
  }
}

.other-header-assembly {
  height: 62px;
}

.hasBack {
  padding-left: 0 !important;
  width: calc(100% - 15px) !important;
}

.status-list {
  li {
    width: 80px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

  }

  li:hover {
    background: #f9fbff;
  }

  .status_ing {
    color: #00C2BB;
  }

  .status_stop {
    color: #999999;
  }
}
</style>
