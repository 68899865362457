<template>
  <div id="synchronizeFiles">
    <div class="closeBtn">
      <div class="top-title">
        <div class="synchronizeTitle">
          <i class="iconfont guoran-tongbu"></i>
          <span>{{synchronizeTitle}}</span>
        </div>
        <div class="companyInformation" v-if="companyName && false">
            <div class="avatar">
              {{companyName.substr(0,2)}}
            </div>
            <span class="companyName">
              {{companyName}}
            </span>
        </div>
        <div class="folderSeat">
          <span v-for="(item,index) in breadcrumb"
                :key="index"
                :class="['breadcrumbItem', index === breadcrumb.length - 1 ? 'last' :'']"
                @click="gobackFolder(item,index)"
          >
            <span class="symbol"> {{index !=0 ? '>' :''}}</span>
            {{item.name}}
          </span>
          <span class="dataTotal">
            {{dataTotal}}
          </span>
        </div>
      </div>
      <i class="iconfont guoran-tongyichicun-guanbi1" @click="closeDrawer"></i>
    </div>
   
    <div class="container" v-if="isSyncStatus">
      <div class="boxLeft">
        <div class="sidebar">
          <folder-management ref="folderManagement" @getFolderData="getFolderData" :synchronize-files="true" @getKnowledge="getKnowledge" :channel="channel"></folder-management>
        </div>
      </div>
      <div class="boxRight">
        
        <div class="tableList">
          <knowledge-table
              sourceType="synchronizeFiles"
              ref="knowledgeTable"
              :currentNode="currentNode"
              :breadcrumb="breadcrumb"
              :channel="channel"
              :synchronizationDrawer="synchronizationDrawer"
              @rowClick="rowClick"
              @getDataTotal="getDataTotal"
              :synchronize-files="true"
          ></knowledge-table>
        </div>
      </div>
    </div>
    <div class="containerEmpty" v-else>
      <img src="../../assets/images/empty_list.png" style="width: 400px;">
      <span style="color: #999999">{{ $t('knowledgeManagement.synchronizeFiles.containerEmpty.emptyText') }}</span>
    </div>
  </div>
</template>

<script>
import FolderManagement from "@/views/knowledgeManagement/folderManagement";
import KnowledgeTable from "@/views/knowledgeManagement/knowledgeTable";
import { crumbs } from "@/assets/js/filterTree";
import { requestUrl } from "@/http/requestUrl";


export default {
  name: "synchronizeFiles",
  components: {KnowledgeTable, FolderManagement },
  data(){
    return{
      currentNode:{},
      checkData:[],
      checkDataCopy:[],
      showTags:false,
      breadcrumb:[],
      checkNewData:[],
      companyName:"",
      dataTotal:0,
      isSyncStatus:false,
      statusTimer:null
    }
  },
  props:["synchronizeFiles","channel","synchronizationDrawer"],
  methods:{
    closeDrawer(){
      this.$emit('closeDrawer')
    },
    handleSelectionChange(val){
      val.map(res => {
        res.children = [];
        if (res.type == 0){
          res.hasChildren = true;
        }
        if (!res.visitPermissionList){
          this.$set(res,'visitPermissionList',[])
        }
        if (!res.parentDirectoryName){
          this.$set(res,'parentDirectoryName',"")
        }
        return res;
      })
      this.checkData = val;
      this.checkDataCopy = JSON.parse(JSON.stringify(val));
      console.debug('handleSelectionChange1',this.checkData)
    },

    /**
     * 左侧目录默认选中第一个请求右侧数据
     * */
    getKnowledge(node){
      console.debug('node',node)
      this.currentNode = node;
      this.breadcrumb = [
        {
          id:node.id,
          name:node.name,
          token:node.token
        }
      ]
      this.$refs.knowledgeTable.getFolderAndFile(this.channel === 5 ? node.token : node.id)
    },
    getFolderData(data,node){
      this.currentNode = data;
      this.$refs.knowledgeTable.currentPage = 1;
      if(this.channel === 5){
        this.$refs.knowledgeTable.getFolderAndFile(data.token);
      } else {
        this.$refs.knowledgeTable.getFolderAndFile(data.id);
      }
      this.breadcrumb = [];
      let arr = crumbs(node);
      // arr = arr.filter(item => item.parentId !== -1);
      this.breadcrumb = arr;
      // let obj = {
      //   id:data.id,
      //   name:data.name
      // }
      // if (data.id !== -1){
      //   this.breadcrumb.push(obj)
      // }
    },
    //返回指定文件夹下
    gobackFolder(item,index){
      this.$refs.knowledgeTable.currentPage = 1;
      this.$refs.knowledgeTable.getFolderAndFile(this.channel === 5 ? item.token : item.id);
      this.currentNode = item;
      this.breadcrumb = this.breadcrumb.splice(0,index+1);
      this.$refs.folderManagement.nodeData = item;

      if (this.breadcrumb.length - 1 >= 0){
        console.debug('breadcrumb',this.breadcrumb[this.breadcrumb.length -1])
        this.$refs.folderManagement.nodeParentData = this.breadcrumb[this.breadcrumb.length -1]
      } else {
        let folder = this.$refs.folderManagement.folderTree
        this.$refs.folderManagement.nodeParentData = folder[0]
      }
      this.$refs.folderManagement.checkDefault = item.id;
      this.$refs.folderManagement.$refs.treeBox.setCurrentKey(item.id);
    },
    //返回顶级节点
    parentFolder(){
      this.breadcrumb = [];
      let folder = this.$refs.folderManagement.folderTree
      this.currentNode = folder[0]
      this.$refs.knowledgeTable.currentPage = 1;
      this.$refs.knowledgeTable.getFolderAndFile(this.currentNode.id);
      this.$refs.folderManagement.checkDefault = this.currentNode.id;
      this.$refs.folderManagement.$refs.treeBox.setCurrentKey(this.currentNode.id);
    },
    //如果是文件夹的话点击生成面包屑
    rowClick(data){
      this.breadcrumb.push(data);
      this.currentNode = data;
      this.$refs.folderManagement.nodeData = data;
      if (this.breadcrumb.length - 2 >= 0){
        this.$refs.folderManagement.nodeParentData = this.breadcrumb[this.breadcrumb.length - 2]
      } else {
        let folder = this.$refs.folderManagement.folderTree
        this.$refs.folderManagement.nodeParentData = folder[0]
      }
      this.$refs.folderManagement.checkDefault = data.id;
      this.$refs.folderManagement.$refs.treeBox.setCurrentKey(data.id);
    },
    /**
     * 获取文件夹下文件/文件夹的数量
     * */
    getDataTotal(data){
      this.dataTotal = data;
    },
    //获取语雀渠道首次同步状态
    getFirstSyncStatus(){
      clearTimeout(this.statusTimer);
      this.statusTimer = null;
      let url = requestUrl.yuQueKnowledgeBase.getSyncStatus;
      let mainId = sessionStorage.getItem('_mainId');
      let corpId = sessionStorage.getItem('_corpId')
      url+='?mainId=' + mainId + '&corpId=' + corpId
      this.$http.get(url).then(res => {
        if (res.data.code == 0){
          if (res.data.data){
            this.isSyncStatus = true;
            this.autoSyncYuQue();
          }
          else {
            this.statusTimer = setTimeout(() => {
              this.getFirstSyncStatus();
            },5000)
          }
        }
      })
    },
    /**
     * 语雀增量更新
     * */
    autoSyncYuQue(){
      let mainId = sessionStorage.getItem('_mainId');
      let url = '';
      if(this.channel === 5){
        url = requestUrl.feishu.syncFeishu + '?mainId=' + mainId
      } else if (this.channel === 8){
        url = requestUrl.channel.autoSyncYuQue + '?mainId=' + mainId
      }
      this.$http.get(url).then(res => {
        if (res.data.code == 0 && res.data.data){
          this.$message({
            message:res.data.data,
            type:'warning',
            duration:3000
          })
        }
      })
    },
  },
  computed:{
    synchronizeTitle () {
      let str = "";
      if (this.channel == 1){
        str = this.$t('knowledgeManagement.synchronizeFiles.syncWechat')
      }else if (this.channel == 5){
        str = this.$t('knowledgeManagement.synchronizeFiles.syncFeishu')
      }else if (this.channel == 8){
        str = this.$t('knowledgeManagement.synchronizeFiles.syncYuque')
      }
      return str
    },

  },
  watch:{

  },
  mounted() {
    this.companyName = sessionStorage.getItem('companyName') ? sessionStorage.getItem('companyName') : '';
    this.getFirstSyncStatus()
  },
  destroyed() {
    clearTimeout(this.statusTimer);
    this.statusTimer = null;
  }
};
</script>

<style scoped lang="less">
#synchronizeFiles{
  padding: 20px;
  height: 100%;
  overflow: hidden;
  margin-bottom: 10px;
  .closeBtn{
    display: flex;
    align-items: center;
    // justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;
    cursor: pointer;
      .top-title{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 36px;
        flex: 1;
        .synchronizeTitle{
          display: flex;
          align-items: center;
          margin-right: 20px;
          // padding-bottom: 14px;
          border-bottom: 2px solid #366AFF;
          height: 36px;
          i{
            margin-right: 6px;
            color: #366aff;
          }
        }
      }
    i{
      font-size: 14px;
    }
  }

  .companyInformation{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    background: #F6F7FB;
    border-radius: 21px;
    font-size: 13px;
    .avatar{
      flex: none;
      width: 28px;
      height: 28px;
      background: #366AFF;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      font-size: 12px;
    }
    .companyName{
      margin-left: 6px;
    }
  }
  .folderSeat{
    display: flex;
    align-items: center;
    // margin-bottom: 20px;
    //margin-top: 20px;
    .enterprise{
      color: #A9B3C6;
      cursor: pointer;
    }
    .breadcrumbItem{
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      margin-right: 2px;
      color: #A9B3C6;
      &.last{
        color:#000;
      }
      .symbol{
        color: #A9B3C6;
      }
    }
    .dataTotal{
      padding: 2px 6px;
      background: #ECF1FF;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #366AFF;
      font-size: 12px;
      margin-left: 10px;
      flex-shrink: 0;
    }
    
  }
  .tableList{
    height: calc(100% - 26px);
    border-radius: 5px;
    box-shadow: 0px 0px 18px 0px rgba(29,55,129,0.07);
  }
  .container{
    display: flex;
    height: calc(100% - 40px);
    .boxLeft{
      height: 100%;
      flex: none;
      width: 260px;
    }
    
    .sidebar{
      height: calc(100% - 30px);
      background-color: #F6F8FD;
      // margin-top: 20px;
      border-radius: 5px;
      /deep/.el-tree{
        background-color: #F6F8FD;
      }
    }
    .boxRight{
      height: 100%;
      margin-left: 16px;
      width: calc(100% - 260px);
      position: relative;
      
      .selectData{
        position: absolute;
        bottom: 10px;
        left: 0px;
        height: 44px;
        background: #344473;
        opacity: 0.6;
        border-radius: 5px;
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .tagItem{
          padding: 0 12px;
          border-right: 1px solid #FFFFFF;
          color: #FFFFFF;
          font-size: 13px;
          cursor: pointer;
          display: flex;
          align-items: center;
        }
        .checkTotal{
          flex: none;
          padding: 0 10px;
          height: 20px;
          background: #ECF1FF;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #366aff;
          margin-left: 8px;
        }
      }
    }
  }
  .containerEmpty{
    height: calc(100% - 20px);
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /deep/#popup-assembly{
    width: 75%;
  }
  .popupAssociated{
    overflow: hidden;
    .title{
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      .numTag{
        flex: none;
        width: 29px;
        height: 20px;
        background: #ECF1FF;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 6px;
      }
    }
  }
}
</style>