import { render, staticRenderFns } from "./synchronizeFiles.vue?vue&type=template&id=44b12811&scoped=true"
import script from "./synchronizeFiles.vue?vue&type=script&lang=js"
export * from "./synchronizeFiles.vue?vue&type=script&lang=js"
import style0 from "./synchronizeFiles.vue?vue&type=style&index=0&id=44b12811&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44b12811",
  null
  
)

export default component.exports