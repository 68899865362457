<template>
  <div id="nav">
    <div class="collapse-handle">
      <!-- <i class="iconfont guoran-askservice-logo03"></i> -->
      <svg
        t="1685434680500"
		class="icon"
		viewBox="0 0 1024 1024"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		p-id="34560"
		width="30"
		height="30">
			<path
				d="M600.617267 944.64h-77.312c-41.984 0-75.776-33.792-75.776-75.776V154.624c0-41.984 33.792-75.776 75.776-75.776h77.312c41.984 0 75.776 33.792 75.776 75.776v714.752c-0.512 41.472-34.304 75.264-75.776 75.264z"
				fill="#FBB03B"
				p-id="34561">
			</path>
			<path
				d="M519.721267 221.696h85.504c8.704 0 14.336-4.608 14.336-12.288s-5.632-12.288-14.336-12.288h-85.504c-8.704 0-14.336 4.608-14.336 12.288s5.632 12.288 14.336 12.288zM519.721267 270.336h85.504c8.704 0 14.336-4.608 14.336-12.288 0-7.168-5.632-12.288-14.336-12.288h-85.504c-8.704 0-14.336 4.608-14.336 12.288s5.632 12.288 14.336 12.288z"
				fill="#E9F3FB"
				p-id="34562">
			</path>
			<path
				d="M948.265267 944.64h-77.312c-41.984 0-75.776-33.792-75.776-75.776V154.624c0-41.984 33.792-75.776 75.776-75.776h77.312c41.984 0 75.776 33.792 75.776 75.776v714.752c0 41.472-33.792 75.264-75.776 75.264z"
				fill="#004FFF"
				p-id="34563">
			</path>
			<path
				d="M867.881267 221.696h85.504c8.704 0 14.336-4.608 14.336-12.288s-5.632-12.288-14.336-12.288h-85.504c-8.704 0-14.336 4.608-14.336 12.288s6.144 12.288 14.336 12.288zM867.881267 270.336h85.504c8.704 0 14.336-4.608 14.336-12.288 0-7.168-5.632-12.288-14.336-12.288h-85.504c-8.704 0-14.336 4.608-14.336 12.288s6.144 12.288 14.336 12.288z"
				fill="#E9F3FB"
				p-id="34564">
			</path>
			<path
				d="M359.465267 599.552H294.953267c-20.48 0-37.376-16.896-37.376-37.376s16.896-37.376 37.376-37.376h65.024c20.48 0 37.376 16.896 37.376 37.376s-16.896 37.376-37.888 37.376z"
				fill="#004FFF"
				p-id="34565">
			</path>
			<path
				d="M124.457267 928.768c-23.04-9.216-34.816-34.816-26.624-58.368L377.385267 131.584c9.216-23.04 34.816-34.816 58.368-26.624 23.04 9.216 34.816 34.816 26.624 58.368L182.825267 902.656c-8.704 23.04-35.328 35.328-58.368 26.112z"
				fill="#004FFF"
				p-id="34566">
			</path>
			<path
				d="M29.225267 927.744c-23.04-9.216-34.816-34.816-26.624-58.368L282.153267 130.56c9.216-23.04 34.816-34.816 58.368-26.624 23.04 9.216 34.816 34.816 26.624 58.368L87.593267 901.632c-9.216 23.04-35.328 34.816-58.368 26.112z"
				fill="#FBB03B"
				p-id="34567">
			</path>
		</svg>
    </div>
    <div class="side-menu-list">
      <template v-if="false">
        <div
            class="side-menu-item"
            v-for="(item,index) in getNavList"
            :key="index"
            @click="gotoLink(item)"
            :class="item.isActive?'active-menu':''"
        >
          <el-tooltip class="item" effect="dark" placement="right" popper-class="sider-bar-tooltip">
            <div slot="content">
              {{item.name}}
            </div>
            <span class="side-menu-item-icon">
            <svgOrIcon
                :icon="item.icon"
                :iconStyle="{backgroundColor:'#FFFFFF'}"
                iconClass="iconfont"
            ></svgOrIcon>
          </span>
          </el-tooltip>
        </div>
      </template>
      <el-menu
          default-active="1"
          class="el-menu-vertical-demo"
          background-color="#333333"
          text-color="#fff"
          active-text-color="#366AFF"
          :router="true"
          :collapse="true"
      >
        <el-menu-item :index="item.link" v-for="item in getNavList" :key="item.link"><i :class="[item.isActive ? 'active-item':'' ,'iconfont ' + item.icon]"></i><span slot="title">{{item.name}}</span></el-menu-item>
      </el-menu>
    </div>

    <div class="switch-system">
        <svg
            @click="showSwitch = true"
            v-if="
                !(
                    activeServiceObj.OPEN == -1 &&
                    activeServiceObj.WORKORDER == -1 &&
                    activeServiceObj.IM == -1 &&
                    activeServiceObj.KNOWLEDGE == -1 &&
                    activeServiceObj.QUESTION == -1
                )
            "
            t="1648203029511"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="211692"
            width="24"
            height="24"
        >
            <path
                d="M969.216 228.352c18.944 0 34.304 15.36 34.304 34.304s-14.848 33.792-33.792 34.304h-99.84c84.992 69.632 125.952 161.28 120.832 262.144-5.632 105.984-62.976 213.504-153.6 294.912-14.336 12.8-35.84 11.264-48.64-2.56-12.8-14.336-11.264-35.84 2.56-48.64 77.824-69.632 125.952-160.256 130.56-246.784 4.608-82.944-30.72-157.696-107.52-214.528v110.08c0 18.944-14.848 33.792-33.792 34.304h-1.024c-18.944 0-33.792-14.848-34.304-33.792V263.168c0-18.944 14.848-33.792 33.792-34.304h1.024l189.44-0.512zM275.456 190.976c9.728 15.872 5.12 36.864-10.752 47.104l-0.512 0.512C168.448 296.96 124.416 378.88 129.536 471.04c3.584 69.12 34.816 140.8 87.04 202.24v-113.664c0-18.944 14.848-33.792 33.792-34.304h1.024c18.944 0 33.792 14.848 34.304 33.792v189.952c0 18.944-14.848 33.792-33.792 34.304H61.952c-18.944 0-34.304-15.36-34.304-34.304s14.848-33.792 33.792-34.304h99.328c-59.392-71.68-95.232-156.16-99.84-239.104-6.144-117.76 50.688-223.232 167.424-295.424 15.872-10.752 37.376-5.632 47.104 10.752z"
                fill="#458FFF"
                p-id="211693"
            ></path>
            <path
                d="M549.376 17.408c52.224 0 94.72 42.496 94.72 94.72v62.976c0 52.224-42.496 94.72-94.72 94.72H486.4c-52.224 0-94.72-42.496-94.72-94.72V112.128c0-52.224 42.496-94.72 94.72-94.72h62.976z"
                fill="#FF9555"
                p-id="211694"
            ></path>
            <path
                d="M549.376 742.912c52.224 0 94.72 42.496 94.72 94.72v61.44c0 52.224-42.496 94.72-94.72 94.72H486.4c-52.224 0-94.72-42.496-94.72-94.72v-61.44c0-52.224 42.496-94.72 94.72-94.72h62.976z"
                fill="#00C2BB"
                p-id="211695"
            ></path>
        </svg>
        <transition name="fade">
            <div v-if="showSwitch" class="switch-box">
                <div class="tr-w">
                    <svg
                        t="1653379591086"
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="2979"
                        width="16"
                        height="16"
                    >
                        <path
                            d="M325.456896 862.27968"
                            p-id="2980"
                            fill="#ffffff"
                        ></path>
                        <path
                            d="M882.05824 862.27968"
                            p-id="2981"
                            fill="#ffffff"
                        ></path>
                        <path
                            d="M236.027904 877.161472"
                            p-id="2982"
                            fill="#ffffff"
                        ></path>
                        <path
                            d="M817.898496 962.556928 817.898496 63.654912 207.63648 513.099776Z"
                            p-id="2983"
                            fill="#ffffff"
                        ></path>
                        <path
                            d="M960.132096 877.161472"
                            p-id="2984"
                            fill="#ffffff"
                        ></path>
                    </svg>
                </div>
                <span
                    @click="showSwitch = false"
                    class="switch-box-close"
                >
                    <i class="arsenal_icon arsenalcuo1"></i>
                </span>
                <span class="switch-box-title">{{$t('common.changeSys')}}</span>
                <div class="switch-box-content">
                    <ActiveService
                        :activeServiceObj="activeServiceObj"
                        :userInfo="userInfo"
                        :userCompany="userCompany"
                    />
                </div>
            </div>
        </transition>
    </div>
  </div>
</template>

<script>
import svgOrIcon from '../components/svgOrIcon';
import { requestUrl } from "@/http/requestUrl";
import ActiveService from "./ActiveService.vue";
export default {
  name: "sideBar",
  data() {
    return {
      testDrawerVisibleP: false,
      testDrawerVisibleS: false,
      isCollapse: true,
      userInfo: {},
      IsActive: false,
      navList: [],
      roles: "",
      tooltipContent: "上传下载中心",
      orderLength: false,
      showContent: false,
      showContentToop: false,
      deriveScreen: false,
      testtttt: false,
      showClientModule: true,
      showSwitch: false,
      activeServiceObj: {
        OPEN: -1,
        IM: -1,
        WORKORDER: -1,
        STATUS: -1,
        ASKAI:-1,
        QUESTION: -1
      },
      rolePrivilegesList: [
        "PORTAL_ENTRANCE_OPEN",
        "PORTAL_ENTRANCE",
        "PORTAL_ENTRANCE_IM"
      ],
      isScrm: false,
      userCompany: {
          status: 0,
          companyName: "",
          modules: [],
      },
    };
  },
  props: {
    checkOrder: Number,
    deriveScreenCheck: Number
  },
  components: {
    svgOrIcon,
    ActiveService
  },
  computed: {
    getNavList(){
      let navList = this.navList;
      navList.forEach(item => {
        console.log('$route',this.$route)
        if (item.link == this.$route.path ||
            (this.$route.meta.belong === 'contract' && item.link.includes('contract_')) ||
            (this.$route.meta.belong === 'channel' && item.link.includes('channel_')) ||
            (this.$route.meta.belong === 'news' && item.link.includes('news_')) ||
            (this.$route.meta.belong === 'knowledge' && item.link.includes('knowledge_')) ||
            (this.$route.meta.belong === 'analysis' && item.link.includes('analysis_'))){
          item.isActive = true
        } else {
          item.isActive = false
        }

        // 立时 功能调整
        if (this.main_isLishi) {
          if (item.link.includes('automation') || item.link.includes('news_announcementManage') || item.link.includes('channel_management') || item.link.includes('contract_template')) {
            item.isHide = true;
          }
          navList = navList.filter(item => !item.isHide);
        }

      })
      return navList
    }
  },
  watch: {
    //添加CNZZ统计
    $route: {
      handler() {
        setTimeout(() => {
          //避免首次获取不到window._czc
          if (window._czc) {
            let location = window.location;
            let contentUrl = location.pathname + location.hash;
            let refererUrl = "/";
            // 用于发送某个URL的PV统计请求，
            window._czc.push(["_trackPageview", contentUrl, refererUrl]);
          }
        }, 300);
      },
      immediate: true // 首次进入页面即执行
    }
  },
  methods: {
    isActivePath(item) {
      let path = this.$route.path
      let belong = this.$route.meta.belong
      if(item.link == path) {
        return true
      } else if(item.link.includes('botMain') && (path.includes('botMain') || (belong && belong.includes('botMain')))) {
        return true
      } else if(item.link.includes('knowledge_') && (path.includes('knowledge_') || (belong && belong.includes('knowledge')))) {
        return true
      } else {
        return false
      }
    },
    gotoLink(item) {
      sessionStorage.removeItem('breadcrumb')
      this.navList.map(items => {
        items.isActive = false;
        return items
      })
      item.isActive = true;
      this.$router.push({
        path:item.link
      })
    },
    getUserCompany() {
      this.$http.get(requestUrl.company.getUserCompanyModule).then(res => {
              if (res.data && res.data.code === "0") {
                  if (res.data.data != null) {
                      this.userCompany = res.data.data;
                      let modules = res.data.data.modules;
                      this.activeServiceObj.STATUS =
                          this.userCompany.status;
                      if (modules != null && modules.length > 0) {
                          modules.forEach((item) => {
                            if (item.sysModule.code === "WORKORDER") {
                              this.activeServiceObj.WORKORDER =  item.sysModule.portalUrl;
                            }
                            if (item.sysModule.code === "IM") {
                                this.activeServiceObj.IM = item.sysModule.portalUrl;
                            }
                            if (item.sysModule.code === "MANAGEMENT") {
                                this.activeServiceObj.MANAGEMENT = item.sysModule.portalUrl;
                            }
                            if (item.sysModule.code === "OPEN") {
                              this.activeServiceObj.OPEN = item.sysModule.portalUrl;
                            }
                            if (item.sysModule.code === "ASKAI") {
                              this.activeServiceObj.ASKAI = item.sysModule.portalUrl;
                            }
                            if (item.sysModule.code === "QUESTION") {
                              this.activeServiceObj.QUESTION = item.sysModule.portalUrl
                            }
                          });
                      }
                      this.userCompany.modules = [...modules];
                  }
              }
          })
        .catch(() => {
            // location.hash = "/auth";
        });
      },
  },
  created() {
    
  },
  mounted() {
    // 立时环境特殊处理
    if (this.main_isLishi) {
      this.$i18n.locale = "en";
    }
    this.getUserCompany();
    const script = document.createElement("script");
    script.src = `https://s95.cnzz.com/z_stat.php?id=${1278715588}&web_id=${1278715588}`;
    script.language = "JavaScript";
    document.body.appendChild(script);
    let mainId = sessionStorage.getItem("_mainId");
    this.navList = [
        {
          type: 1,
          name: this.$t('common.knowledge'),
          link: "/knowledge_Management",
          index: "1",
          icon: "iconfont guoran-tongyichicun-zujianzhishiku",
          isHide: false,
          isActive: true,
          image: ''
        },
        {
          type:1,
          name:this.$t('common.knowledgeTags'),
          icon:"iconfont guoran-biaoqian2",
          link:"/labelIndex",
          isHide: false,
          isActive: false,
        },
        {
          type:1,
          name:this.$t('common.channel'),
          icon:"iconfont guoran-tongyichicun-qudaoguanli",
          link:"/channel_management",
          isHide: false,
          isActive: false,
        },
        {
          type: 1,
          name: this.$t('common.automation'),
          link: "/automation",
          index: "3",
          icon: "iconfont guoran-tongyichicun-14-12-zidonghualiucheng",
          isHide: false,
          isActive: true,
          image: ''
        },
        // {
        //   type: 1,
        //   name: this.$t('common.automation'),
        //   link: "/automation",
        //   index: "3",
        //   icon: "iconfont guoran-tongyichicun-14-12-zidonghualiucheng",
        //   isHide: false,
        //   isActive: true,
        //   image: ''
        // },
        {
          type:1,
          name:this.$t('common.news'),
          icon:"iconfont guoran-xinwengao",
          link:"/news_announcementManage",
          isHide: false,
          isActive: false,
        },
        // {
        //   type: 1,
        //   name: this.$t('common.statisticalAnalysis'),
        //   link: "/analysis_knowledgeOverview",
        //   index: "3",
        //   icon: "iconfont guoran-tongjifenxi",
        //   isHide: false,
        //   isActive: true,
        //   image: ''
        // },
      ]
      console.log("+++++++++new navlist 12 +++++++++");
      
    /*合同针对指定企业显示 c0d8390738cc4730bb2fc8ec2c39ce1c*/
    if (mainId === 'c0d8390738cc4730bb2fc8ec2c39ce1c' || mainId === 'dd3f376f901548b8a5e3530434be7c9e' || mainId === 'ab0f90737c8b4f2d85ba2157e4473110'){
      this.navList.splice(this.navList.length - 1 ,0, {
        type: 1,
        name: this.$t('common.contract'),
        link: "/contract_template",
        index: "4",
        icon: "iconfont guoran-chanpin",
        isHide: false,
        isActive: false,
        image: ''
      })
    }
  }
};
</script>

<style scoped lang="less">


#nav {
  width: 64px;
  height:100vh;
  background-color: #1d2e54;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  .collapse-handle {
    flex: none;
    color: whitesmoke;
    height: 55px;
    text-align: center;
    line-height: 55px;
     background-color: #366aff;
    display: flex;
    align-content: center;
    justify-content: center;
	align-items: center;

    i {
      font-size: 36px;
    }

    img {
      margin-top: 10px;
    }

    em {
      cursor: pointer;
      font-size: 20px;
    }
  }

  .side-menu-list {
    //margin-top: 10px;
    min-height: calc(100vh - 142px);
    max-height: calc(100vh - 142px);
    overflow: hidden;
    overflow-y: auto;
    // display: flex;
    // align-items: center;
    // flex-direction: column;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    margin: 10px auto;
    .side-menu-item {
      height: 50px;
      flex: 1 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-bottom: 20px;
      color: #ffffff;
      width: 48px;
      .side-menu-item-icon {
        //height: 38px;
        //width: 38px;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          color: white;
          font-size: 20px;
        }
      }

      .side-menu-item-name{
        font-size: 12px;
        margin-top: 4px;
      }

      .collapse-hide-show {
        height: 18px;
        background-color: #2b3d65;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;

        i {
          font-weight: 600;
          color: #a4b2cf;
        }
      }
    }
    .active-menu {
      background-color: #366aff;
      border-radius: 5px;
    }
    .collapse-hide-show {
      height: 18px;
      background-color: #2b3d65;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;

      i {
        font-weight: 600;
        color: #a4b2cf;
      }
    }
    /deep/.el-menu{
      .el-tooltip{
        padding: 0!important;
      }
    }
  }

  .side-menu-list::-webkit-scrollbar {
    width: 0px !important;
  }

  .switch-system {
    flex: none;
    height: 40px;
    margin-bottom: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .switch-box {
      z-index: 9999;
      height: 200px;
      background: #ffffff;
      box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.13);
      border-radius: 5px;
      position: absolute;
      left: 65px;
      bottom: 40px;

      svg {
        position: absolute;
        left: -10px;
        bottom: 10px;
      }

      .switch-box-close {
        position: absolute;
        right: -10px;
        top: -10px;
        display: block;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: #ffffff;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.21);
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          display: inline-block;
          margin: 0 auto;
          font-size: 18px;
          color: #616161;
        }
      }

      .switch-box-title {
        display: block;
        text-align: center;
        height: 17px;
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        line-height: 28px;
        margin: 16px 0 25px;
      }

      .switch-box-content {
      }
    }
  }
}
.active-item{
  color: white !important;
  font-size: 20px;
  display: inline-block;
  width: 38px !important;
  height: 38px !important;
  background-color: #366aff;
  border-radius: 5px;
}
/deep/.el-menu-vertical-demo{
  background-color: #1d2e54!important;
}
.el-menu-item{
  background-color: #1d2e54!important;
  padding: 0 !important;
  width: 64px !important;
  height: 38px;
  line-height: 38px;
  margin-bottom: 20px;
  text-align: center;
  i{
    font-size: 20px;
    color: #FFFFFF;
  }
}
</style>
