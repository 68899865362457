<template>
  <div id="labelIndex">
    <template v-if="tagList.length == 0">
      <div class="tag-default">
        <img src="../../assets/images/tagDefault.png"/>
        <div class="add-tag" @click="addTag">{{ $t('knowledgeManagement.knowledgeTag.addTagText') }}</div>
      </div>
    </template>
    <template>
      <div class="indexHeader">
        <div class="headerL">
          <i class="iconfont guoran-a-18-13" @click="deleteTag" v-if="false"></i>
          <el-divider direction="vertical" v-if="false"></el-divider>
          <el-input
              :placeholder="$t('knowledgeManagement.knowledgeTag.searchTag')"
              prefix-icon="el-icon-search"
              v-model="keyword"
              style="width: 100%"
              @keyup.enter.native="searchTag"
              @input="searchTagNull"
          >
          </el-input>
        </div>
        <div class="headerR" @click="addTag">
          <i class="iconfont guoran-a-18-35"></i>
          <span>{{ $t('knowledgeManagement.knowledgeTag.addTagText') }}</span>
        </div>
      </div>
      <div class="labelTable">
        <tag-tree
            ref="tagTree"
            @addTag="addTag"
            @updateTag="updateTag"
            @loadMore="loadMore"
            @saveTag="saveAITag"
        ></tag-tree>
      </div>
    </template>
    <el-drawer
        :title="$t('knowledgeManagement.knowledgeTag.drawerTitle')"
        :visible.sync="tagDrawer"
        size="740px"
        :withHeader="false"
        :wrapperClosable="false"
        :destroy-on-close="true"
    >
      <div class="drawerHeader">
        <span class="tagSet">{{ $t('knowledgeManagement.knowledgeTag.tagSet') }}</span>
        <i class="iconfont guoran-shanchu" @click="tagDrawer = false;tagDrawerC = false"></i>
      </div>
      <div class="drawerContainer">
        <el-form ref="form" label-position="top" :model="tagContent" v-if="tagDrawerC">
          <el-form-item>
            <span slot="label">
              {{$t('knowledgeManagement.knowledgeTag.tagName')}}<span style="color: red">*</span>
            </span>
            <el-input v-model="tagContent.name" :placeholder="$t('knowledgeManagement.knowledgeTag.placeholder')"></el-input>
          </el-form-item>
          <el-form-item>
            <span slot="label">{{$t('knowledgeManagement.knowledgeTag.followTag')}}<span style="color: red">*</span></span>
            <el-select-tree ref="selectTree"></el-select-tree>
          </el-form-item>
          <el-form-item v-if="false">
            <span slot="label">
              <span>标签描述</span>
              <span style="color: #A9B3C6;margin-left: 4px">填写可根据什么内容可识别到该标签，如不填写，则由模型自行识别</span>
            </span>
            <el-input
                type="textarea"
                :rows="4"
                placeholder="请输入内容"
                v-model="tagContent.description">
            </el-input>
          </el-form-item>
          <el-form-item v-if="false">
            <div class="intelligentRecognition">
              <el-switch
                  v-model="tagContent.intelligentRecognition"
                  active-color="#366aff">
              </el-switch>
              <span class="title">智能识别</span>
              <span class="tips">GPT模型识别</span>
            </div>
            <template v-if="tagContent.intelligentRecognition">
              <div class="model-title" >
                <span>模型指令</span>
                <span class="tips">非必填，如不填写，则由模型自行识别</span>
              </div>
              <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="请输入内容"
                  v-model="tagContent.modelInstructions">
              </el-input>
            </template>
          </el-form-item>
          <el-form-item label="规则">
            <span slot="label">
              {{$t('knowledgeManagement.knowledgeTag.tagRule')}}
              <span class="tips">{{$t('knowledgeManagement.knowledgeTag.ruleTips')}}</span>
            </span>
            <rule-conditions ref="ruleCondition"></rule-conditions>
          </el-form-item>
        </el-form>
      </div>
      <div class="drawerFooter">
        <div class="footerLeft" v-if="tagContent._id" @click="deleteTags">
          <i class="iconfont guoran-a-18-13"></i>
        </div>
        <div class="footerRight">
          <div class="close-btn btn-item" @click="closeDrawer">{{$t('knowledgeManagement.knowledgeTag.cancel')}}</div>
          <div class="confirm-btn btn-item" @click="saveTag">{{$t('knowledgeManagement.knowledgeTag.saveTag')}}</div>
        </div>
      </div>

    </el-drawer>
    <delete-dialog @closeEvent="deleteTagDialog = false" v-if="deleteTagDialog">
      <div slot="popup-container" class="popup-container">
        <div class="delete-title">{{$t('knowledgeManagement.knowledgeTag.batchDelete')}}</div>
        <div class="prompt-text">
          {{$t('knowledgeManagement.knowledgeTag.deleteTips')}}
        </div>
      </div>
      <div slot="dialog-footer">
        <el-button @click="deleteTagDialog = false" size="small" plain class="cancel-btn">{{$t('knowledgeManagement.knowledgeTag.cancel')}}</el-button>
        <el-button @click="confirmTags" style="background-color: #FE5965 ;color: white" size="small"  class="confirm-btn">{{$t('knowledgeManagement.knowledgeTag.deleteText')}}
        </el-button>
      </div>
    </delete-dialog>
  </div>
</template>

<script>
import TagTree from "@/views/labelManagement/tagTree";
import RuleConditions from "@/views/labelManagement/components/ruleConditions";
import ElSelectTree from "@/views/labelManagement/components/elSelectTree";
import { requestUrl } from "@/http/requestUrl";
import DeleteDialog from "@/components/deleteDialog";
export default {
  name: "labelIndex",
  components: { DeleteDialog, ElSelectTree, RuleConditions, TagTree },
  data(){
    return{
      keyword:"",
      tagDrawer:false,
      tagDrawerC:false,
      tagList:[],
      tagContent:{
        name:"",
        parentId:"-1",
        description:"",
        intelligentRecognition:false,
        ruleList:[],
        modelInstructions:""
      },
      batchDeleteDialog:false,
      pageSize:20,
      currentPage:1,
      deleteTagDialog:false,
      checkKeys:[]
    }
  },
  methods:{
    addTag(id,name){
      this.tagDrawer = true;
      this.tagContent = {
        name:"",
        parentId:"-1",
        description:"",
        intelligentRecognition:false,
        ruleList:[],
        modelInstructions:""
      }
      this.$nextTick(() => {
        this.tagDrawerC = true;
        setTimeout(() => {
          if (name){
            this.tagContent.parentId = id;
            this.$refs.selectTree.selectLabel = name;
            this.$refs.selectTree.selectValue = id;
          } else {
            this.$refs.selectTree.selectLabel = this.$t('common.rootdirectory');
            this.$refs.selectTree.selectValue = "-1";
          }
        },100)
      })
    },
    closeDrawer(){
      this.tagDrawer = false;
      this.tagDrawerC = false;
    },
    getTagList(){
      let url = requestUrl.tagManage.tagTree;
      let corpId = sessionStorage.getItem('_corpId');
      let mainId = sessionStorage.getItem('_mainId');
      let uid = sessionStorage.getItem("_uid");
      let parentId = this.tagContent.parentId;
      url += '?mainId=' + mainId + '&corpId=' + corpId + '&uid=' + uid + '&parentId=' + parentId;
      this.$http.get(url).then(res => {
        if (res.data.code == 0){
          this.tagList = res.data.data;
        }
      })
    },
    async saveTag(){
      const ruleList = this.$refs.ruleCondition.conditionsList;
      const tagDirectory = this.$refs.selectTree.selectValue;
      this.tagContent.parentId = tagDirectory;
      this.tagContent.ruleList = ruleList;
      if (!this.tagContent.name){
        this.$message({
          message:this.$t('knowledgeManagement.knowledgeTag.nameRequired'),
          type:"warning",
        })
        return
      }
      if (!this.tagContent.parentId){
        this.$message({
          message:this.$t('knowledgeManagement.knowledgeTag.followTagRequired'),
          type:"warning",
        })
        return
      }
      //相同名称的标签不允许保存
      let flag = await this.findTag(this.tagContent.name,this.tagContent._id);
      let url = requestUrl.tagManage.saveTag;
      let corpId = sessionStorage.getItem('_corpId');
      let mainId = sessionStorage.getItem('_mainId');
      let uid = sessionStorage.getItem("_uid");
      if (flag){
        this.$message({
          message:this.$t('knowledgeManagement.knowledgeTag.checkTagName'),
          type:"warning"
        })
        return
      }
      if (this.tagContent._id){
        let putUrl = requestUrl.tagManage.updateTag + this.tagContent._id
        this.$http.put(putUrl,this.tagContent).then(res => {
          if (res.data.code == 0){
            this.$message({
              message:this.$t('knowledgeManagement.knowledgeTag.editTagSuccess'),
              type:'success'
            })
            this.tagDrawer = false;
            this.tagDrawerC = false;
            this.$refs.tagTree.loadNode(this.$refs.tagTree.rootTreeData,this.$refs.tagTree.rootResolve);
          }
        })
      } else {
        url += '?mainId=' + mainId + '&corpId=' + corpId + '&uid=' + uid;
        this.$http.post(url,this.tagContent).then(res => {
          if (res.data.code == 0){
            this.$message({
              message:this.$t('knowledgeManagement.knowledgeTag.addSuccess'),
              type:'success'
            })
            this.tagDrawer = false;
            this.tagDrawerC = false;
            this.$refs.tagTree.loadNode(this.$refs.tagTree.rootTreeData,this.$refs.tagTree.rootResolve);
            if (this.$refs.tagTree.aiDrawer){
              this.$refs.tagTree.saveTagSuccess()
            }
          }
        })
      }

    },
    updateTag(node,data){
      this.tagContent = JSON.parse(JSON.stringify(data));
      var tagDirectory = {
        name: this.$t('common.rootdirectory'),
        id:"-1",
        ruleList:data.ruleList
      }
      if (data.parentId !=="-1" && node.parent && node.parent.data){
        tagDirectory = {
          name:node.parent.data.name,
          id:node.parent.data._id,
          ruleList:node.parent.data.ruleList
        }
      }
      console.log(node,tagDirectory,data)
      this.tagDrawer = true;
      this.$nextTick(() => {
        this.tagDrawerC = true;
        setTimeout(() => {
          this.$refs.selectTree.selectLabel = "";
          this.$refs.selectTree.selectValue = "";
          this.$refs.selectTree.selectLabel = tagDirectory.name;
          this.$refs.selectTree.selectValue = tagDirectory.id;
          this.$refs.ruleCondition.conditionsList = data.ruleList;
          console.log(this.$refs.selectTree.selectLabel)
        },200)
      })
    },
    async findTag(name,id){
      let flag = false;
      let url = requestUrl.tagManage.duplicateTags;
      let corpId = sessionStorage.getItem('_corpId');
      let mainId = sessionStorage.getItem('_mainId');
      url += '?mainId=' + mainId + '&corpId=' + corpId + '&keyword=' + name;
      await this.$http.get(url).then(res => {
        let data = res.data.data;
        if (id){
          data = res.data.data.filter(item => item._id !== id);
        }
        if (data.length > 0){
          flag = true
        }
      })
      return flag
    },
    searchTag(){
      let url = requestUrl.tagManage.searchTag;
      let corpId = sessionStorage.getItem('_corpId');
      let mainId = sessionStorage.getItem('_mainId');
      if (this.keyword){
        url += '?mainId=' + mainId + '&corpId=' + corpId + '&keyword=' + this.keyword + '&page=' + this.currentPage + '&pageSize=' + this.pageSize;
        this.$http.get(url).then(res => {
          if (res.data.code == 0 && res.data.data && res.data.data.list){
            let data = res.data.data.list.map(item => {
              item.isLeaf = true;
              return item
            })
            this.$refs.tagTree.hasNextTag = (res.data.data.total - (this.pageSize * this.currentPage)) > 0;
            if (this.currentPage === 1){
              this.$refs.tagTree.tagList = data;
            } else if (this.currentPage > 1){
              this.$refs.tagTree.tagList =  this.$refs.tagTree.tagList.concat(data);
            }

          }
        })
      }
    },
    searchTagNull(val){
      if (!val){
        this.currentPage = 1;
        this.$refs.tagTree.tagList = [];
        this.$refs.tagTree.loadNode(this.$refs.tagTree.rootTreeData,this.$refs.tagTree.rootResolve);
      }
    },
    deleteTags(){
      this.$refs.tagTree.deleteTag(this.tagContent,this.tagContent);
    },
    loadMore(){
      this.currentPage += 1;
      this.searchTag();
    },
    saveAITag(data,parentName){
      this.tagDrawer = true;
      this.tagContent = {
        name:data.name,
        parentId:data.parentId,
        description:"",
        intelligentRecognition:false,
        ruleList:[],
        modelInstructions:"",
        aiCreate:true
      }
      this.$nextTick(() => {
        this.tagDrawerC = true;
        setTimeout(() => {
          this.tagContent.parentId = data.parentId;
          this.$refs.selectTree.selectLabel = parentName;
          this.$refs.selectTree.selectValue = data.parentId;
        },100)
      })
    },
    deleteTag(){
      this.checkKeys = this.$refs.tagTree.$refs.tree.getCheckedKeys();
      if (!this.checkKeys || this.checkKeys.length == 0){
        this.$message({
          message:this.$t('knowledgeManagement.knowledgeTag.selectDeleteTag'),
          type:"warning"
        })
        return
      }
      this.deleteTagDialog = true;
      console.log('data',this.checkKeys)
    },
    confirmTags(){
      let url = requestUrl.tagManage.batchDeleteTags;
      let obj = {
        ids:this.checkKeys
      }
      this.$http.post(url,obj).then(res => {
        if (res.data.code == 0){
          this.$message({
            message:this.$t('knowledgeManagement.knowledgeTag.deleteSuccess'),
            type:'success'
          })
          this.deleteTagDialog = false;
          this.currentPage = 1;
          this.$refs.tagTree.isIndeterminate = false;
          this.$refs.tagTree.checkAll = false;
          this.$refs.tagTree.loadNode(this.$refs.tagTree.rootTreeData,this.$refs.tagTree.rootResolve);
        }
      })
    }
  },
  mounted() {
  }
};
</script>

<style scoped  lang="less">
#labelIndex{
  width: 100%;
  .tag-default{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    height: 100%;
    .add-tag{
      flex: none;
      width: 100px;
      height: 36px;
      background: #366AFF;
      border-radius: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      margin-top: 30px;
      cursor: pointer;
    }
  }
  .indexHeader{
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #ffffff;
    width: calc(100% - 32px);
    /deep/.el-input__inner{
      border: none;
    }
    .headerL{
      display: flex;
      align-items: center;
      flex: 0.9;
      .guoran-a-18-13{
        margin-right: 4px;
        font-size: 18px;
        color: #616161;
      }
    }
    .headerR{
      flex: none;
      width: 95px;
      height: 31px;
      background: #366AFF;
      border-radius: 15px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .iconfont{
        margin-right: 4px;
        font-size: 14px;
      }
    }
  }
  .labelTable{
    width: 100%;
    height: calc(100% - 60px);
  }
  .drawerHeader{
    width: 100%;
    //height: 45px;
    background: #F6F8FD;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    .tagSet{
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      padding: 12px 0;
      border-bottom: 2px solid #366aff;
    }
    .guoran-shanchu{
      color: #616161;
      font-size: 18px;
    }
  }
  .drawerContainer{
    height: calc(100vh - 110px);
    overflow-y: scroll;
    padding: 20px;
    /deep/.el-form-item__label{
      line-height: 30px;
      padding-bottom: 0;
      color: #616161;
    }
    .tips{
      color: #A9B3C6;
      padding-left: 10px;
    }
    .intelligentRecognition{
      display: flex;
      align-items: center;
      .title{
        padding-left: 10px;
      }
    }
    .model-title{
      display: flex;
      align-items: center;
    }
  }
  .drawerFooter{
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    .footerLeft{
      flex: none;
      width: 34px;
      height: 34px;
      background: #FFFFFF;
      border: 1px solid #E0E6F7;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .footerRight{
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-end;
      .btn-item{
        flex: none;
        width: 86px;
        height: 32px;
        border-radius: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .close-btn{
        background-color: #ffffff;
        color: #000000;
        border: 2px solid #E0E6F7;
      }
      .confirm-btn{
        color: #ffffff;
        background: #366AFF;
        margin-left: 8px;
        border: 2px solid #366AFF;
      }
    }
  }
}
</style>