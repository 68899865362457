const redirectUri = {
    sso: process.env.VUE_APP_CONFIG_ENV === 'production' ? process.env.VUE_APP_SSO_PATH : 'https://test.signin.askbot.cn',
    production: {
        staff: process.env.VUE_APP_OPEN_PATH,
        callback: process.env.VUE_APP_PERSONAL,
        appId: "cli_a2611713cc795013",
        authWx: process.env.VUE_APP_AUTHWX_PATH,
        component_appid: "wx5cc5391649410ef8",
        wisdom: process.env.VUE_APP_WISDOM_PATH,
    },
    development: {
        staff: 'https://test.open.askbot.cn',
        callback: 'https://portal.test.askbot.cn/',
        appId: "cli_a217d98a6af9900e",
        authWx: 'https://test.auth.wx.guoranbot.com',
        component_appid: "wx716d3c9c26092d55",
        wisdom: "https://wisdom.test.askbot.cn",
    }
};

function redirect2LoginPage() {
    let redirect = window.location.origin + '/#/';
    console.log('redirect:', redirect)
    if(window.location.origin == 'https://wisdom.ebot.isheely.com') {
        window.location = 'https://signin.ebot.isheely.com' + '?redirect_uri=' +  encodeURIComponent(redirect)
    } else {
        window.location = redirectUri.sso + '?redirect_uri=' + encodeURIComponent(redirect);
    }
}

export { redirectUri, redirect2LoginPage }