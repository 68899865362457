<template>
  <div class="knowledge-permissions">
    <popup-com @closeEvent="closePopup" v-if="knowledgePermissions">
      <div slot="popup-name">{{ $t('knowledgeManagement.folderManagement.permission') }}</div>
      <div slot="popup-tip">{{ $t('knowledgeManagement.folderManagement.setKnowledgePermissions') }}</div>
      <div id="knowledge-permission-con" slot="popup-con">
        <el-form label-position="top" label-width="80px">
          <el-form-item>
            <span slot="label">
              {{ $t('knowledgeManagement.folderManagement.form.readPermissions') }}
              <span style="color: red">*</span>
            </span>
            <div class="permissions-select">
              <div class="select-input" @click="setPermission">
                <template v-if="permissionsObj.permissionsList.length">
                  <div class="permission-list">
                    <div v-for="(item,index) in permissionsObj.permissionsList" :key="index"  class="permissionItem">
                      <template v-if="item.type === 1">
                        <div class="departAvatar">
                          <i class="iconfont guoran-chanpin"></i>
                        </div>
                      </template>
                      <template v-else>
                        <div class="avatar" v-if="item.name">
                          {{(item.name).substr(0,1)}}
                        </div>
                      </template>
                      <span class="label aa" v-if="item.name">
                      <open-data :openid="item.name" :type="item.type == 1 ? 'departmentName' :'userName'" v-if="isUseOpenData()"></open-data>
                      <template v-else>
                        {{item.name}}
                      </template>
                    </span>
                      <span class="close" @click.stop="closePermission(index)">
                      <i class="iconfont guoran-shanchu"></i>
                    </span>
                    </div>
                  </div>
                </template>
                <span v-else class="placeholder">{{ $t('knowledgeManagement.folderManagement.permissionDialog.selectPlaceholder') }}</span>
                <i class="el-icon-arrow-down"></i>
              </div>
            </div>
          </el-form-item>
          <el-form-item :label="$t('knowledgeManagement.folderManagement.form.editPermissions')" v-if="!_isLiBang()">
            <account-management ref="editAccountManagement"></account-management>
          </el-form-item>
          <el-form-item :label="$t('knowledgeManagement.folderManagement.form.managePermissions')">
            <account-management ref="accountManagement"></account-management>
          </el-form-item>
          <div class="form-item">
            <div slot="label" class="form-item-label">
              <div class="labelLeft">
                <div class="evaluate-table-switch">
                  <el-switch
                      @click.native.prevent="changeSearch('switch')"
                      :width="42"
                      v-model="permissionsObj.textWatermark"
                      active-color="#366AFF"
                      inactive-color="#E2E2E2">
                  </el-switch>
                  <span
                      class="switch-open-icon"
                      @click="changeSearch('open')"
                      v-if="permissionsObj.textWatermark">
                <i class="iconfont guoran-a-16-17"></i>
              </span>
                  <span
                      class="switch-close-icon"
                      @click="changeSearch('close')"
                      v-if="!permissionsObj.textWatermark">
                <i class="arsenal_icon arsenalcuo1"></i>
              </span>
                </div>
                <span class="tips-text">{{ $t('knowledgeManagement.folderManagement.form.watermark') }}</span>
              </div>
              <div class="labelRight">
                <el-popover
                    placement="bottom"
                    width="100"
                    trigger="click"
                    content=""
                    ref="watermark"
                >
                  <div class="variable">
                    <div class="variableItem" @click="selectVariable('userName')">
                      {{ $t('knowledgeManagement.folderManagement.form.visitorName') }}
                    </div>
                    <div class="variableItem" @click="selectVariable('phone')">{{ $t('knowledgeManagement.folderManagement.form.visitorPhone') }}</div>
                  </div>
                  <span slot="reference" v-show="permissionsObj.textWatermark">{{ $t('knowledgeManagement.folderManagement.form.insertVariable') }}</span>
                </el-popover>
              </div>
            </div>
            <ckeditor
                :editor="ckeditor.editor"
                v-model="permissionsObj.textWatermarkValue"
                :config="editorConfig"
                @focus="(zh,editor)=>currentEditor=editor"
                @ready="(editor)=>currentEditor=editor"
                v-if="permissionsObj.textWatermark"
            ></ckeditor>
          </div>
          <div class="form-item download">
            <div slot="label" class="form-item-label">
              <div class="labelLeft">
                <div class="evaluate-table-switch">
                  <el-switch
                      @click.native.prevent="changeDownSearch('switch')"
                      :width="42"
                      v-model="permissionsObj.isDownload"
                      active-color="#366AFF"
                      inactive-color="#E2E2E2">
                  </el-switch>
                  <span
                      class="switch-open-icon"
                      @click="changeDownSearch('open')"
                      v-if="permissionsObj.isDownload">
                <i class="iconfont guoran-a-16-17"></i>
              </span>
                  <span
                      class="switch-close-icon"
                      @click="changeDownSearch('close')"
                      v-if="!permissionsObj.isDownload">
                <i class="arsenal_icon arsenalcuo1"></i>
              </span>
                </div>
                <span class="tips-text">{{ $t('knowledgeManagement.folderManagement.form.isDownload') }}</span>
              </div>
            </div>
            <el-select v-model="permissionsObj.downloadType" :placeholder="$t('knowledgeManagement.folderManagement.permissionDialog.selectPlaceholder')" style="width: 100%" v-if="permissionsObj.isDownload">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
        </el-form>
      </div>
      <div slot="dialog-footer">
        <el-button  plain size="small" @click="closePopup" class="cancel-btn">{{ $t('knowledgeManagement.folderManagement.cancel') }}</el-button>
        <el-button  type="primary" size="small" @click="confirmKnowledgePermissions"  class="confirm-btn">{{ $t('knowledgeManagement.folderManagement.confirm') }}</el-button>
      </div>
    </popup-com>
    <employee-structure
        ref="employeeStructure"
        :permissionList="permissionsObj.permissionsList"
        @confirmPermission="confirmPermission"
        @cancelPermission="cancelPermission"
        @onNodeClick="permissionNodeClick"
        v-if="permissionDialog"
    ></employee-structure>
  </div>
</template>

<script>
import PopupCom from "@/components/popup";
import EmployeeStructure from "@/components/employeeStructure";
import OpenData from "@/components/openData";
import AccountManagement from "@/components/accountManagement";
import BalloonEditor from 'ckeditor';
export default {
  name: "knowledgePermissions",
  components: { AccountManagement, OpenData, EmployeeStructure, PopupCom },
  data(){
    return{
      knowledgePermissions:true,
      permissionsListCopy:"[]",
      permissionDialog:false,
      permissionsObj:{
        permissionsList:[],
        accountManagement:[],
        editPermissions:[],
        textWatermark:false,
        isDownload:false,
        textWatermarkValue:"",
        downloadType:2
      },
      editorConfig: {
        extraPlugins: [],
        toolbar: [],
        placeholder: this.$t('common.placeholder')
      },
      ckeditor: {
        editor: BalloonEditor
      },
      currentEditor:null,
      options:[
        {
          label: this.$t('allowDownloadOption.read'),
          value:2
        },
        {
          label: this.$t('allowDownloadOption.manage'),
          value:3
        },
        {
          label: this.$t('allowDownloadOption.edit'),
          value:4
        },
        {
          label: this.$t('allowDownloadOption.all'),
          value:1
        }
      ]
    }
  },
  methods:{
    closePopup(){
      this.knowledgePermissions = false;
      this.$parent.permissionDialog = false;
    },
    setPermission(){
      this.permissionDialog = true;
      this.$nextTick(() => {
        this.$refs.employeeStructure.permissionDialog = true;
      })
    },
    changeTextWatermark(){
      this.permissionsObj.textWatermarkValue = "";
    },
    confirmKnowledgePermissions(){
      // this.knowledgePermissions = false;
      // this.$parent.permissionDialog = false;
      let accountManagement = this.$refs.accountManagement.managePermission;
      this.permissionsObj.accountManagement = accountManagement;
      if(this.$refs.editAccountManagement) {
        this.permissionsObj.editPermissions = this.$refs.editAccountManagement.managePermission;
      } else {
        this.permissionsObj.editPermissions = [];
      }
      this.$emit('confirmKnowledgePermissions',this.permissionsObj)
    },
    permissionNodeClick(data){
      console.log(data,'data');
      // let corpId = sessionStorage.getItem('_corpId');
      if (data.checked){
        let obj = {
          id:data.id,
          type:data.type !== undefined ? data.type : (data.source !== undefined ? 0 : 1),
          name:data.label ? data.label : data.name,
        }
        // obj.iframeUrl = `${this.currentUrl}?corpId=${corpId}&openid=${obj.name}&type=${obj.type == 1 ? 'departmentName' : 'userName'}&style=2&datas=${obj.id + ',' + obj.type + ',' + obj.label}`
        this.permissionsObj.permissionsList.push(obj)
      } else {
        this.permissionsObj.permissionsList = this.permissionsObj.permissionsList.filter(item => {
          return item.id !== data.id
        });
      }
    },
    confirmPermission(){
      // this.permissionList = list;
      this.$refs.employeeStructure.permissionDialog = false;
      this.permissionDialog = false;
    },
    cancelPermission(){
      this.permissionsObj.permissionsList = JSON.parse(this.permissionsListCopy);
      this.$refs.employeeStructure.permissionDialog = false;
      this.permissionDialog = false;
    },
    closePermission(index){
      this.permissionsObj.permissionsList.splice(index,1);
    },
    changeSearch(type){
      this.permissionsObj.textWatermarkValue = '';
      if (type === 'close'){
        this.permissionsObj.textWatermark = true;
      } else if (type === 'open'){
        this.permissionsObj.textWatermark = false;
      }
    },
    changeDownSearch(type){
      this.permissionsObj.downloadType = 2;
      if (type === 'close'){
        this.permissionsObj.isDownload = true;
      } else if (type === 'open'){
        this.permissionsObj.isDownload = false;
      }
    },
    selectVariable(data){
      let command = this.currentEditor.commands.get("insertAskComponent");
      command.execute({
        tag: "span",
        options: {
          name: data == 'userName' ? '访问者姓名' : '访问者手机号后四位',
          data: data,
        },
      });
      this.$refs.watermark.doClose();
    }
  }
};
</script>

<style scoped lang="less">
#knowledge-permission-con{
  .permissions-select{
    background-color: #FFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    padding: 0 15px;
    outline: 0;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;
    .select-input{
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      .permission-list{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
      .placeholder{
        color: #dcdfe6;
        line-height: 36px;
      }
      /deep/.el-tag{
        margin-right: 8px;
      }
      .permissionItem{
        display: flex;
        align-items: center;
        margin: 4px 8px 4px 0;
        background-color: #E8EDF7;
        border-radius: 3px;
        padding: 0px 4px;
        font-size: 12px;
        color: #010101;
        height: 26px;
        .avatar{
          flex: none;
          width: 21px;
          height: 21px;
          background: #366AFF;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #FFFFFF;
          font-size: 12px;
        }
        .departAvatar{
          flex: none;
          width: 21px;
          height:21px;
          background: #918EFF;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;
          i{
            color: #FFFFFF;
            font-size: 12px;
          }
        }
        .label{
          padding: 0px 4px;
          max-width: 70px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 0;
          height: 26px;
          line-height: 26px;
        }
        iframe{
          height: 22px;
          cursor: pointer;
        }
      }
    }
  }
  .visitor-watermark{
    .tips{
      margin-left: 20px;
      color: #dcdfe6;
    }
  }
  /deep/.el-switch__label.is-active{
    color: #303133;
  }
  .form-item{
    .form-item-label{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      .labelLeft{
        display: flex;
        align-items: center;
        .evaluate-table-switch {
          display: flex;
          flex-direction: row;
          height: 25px;
          justify-content: space-between;
          cursor: pointer;
          position: relative;
          text-align: center;
          align-items: center;

          .el-switch {
            text-align: center;
          }

          .switch-open-icon {
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            left: 3px;

            i {
              color: white;
              font-size: 16px;
            }
          }

          .switch-close-icon {
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            left: 21px;
            i {
              color: #6e6b7b;
              font-size: 14px;
              font-weight: 600;
            }
          }

          i {
            color: #366aff;
            font-size: 18px;
          }
        }
        .tips-text{
          margin-left: 10px;
        }
      }
      .labelRight{
        color: #366aff;
        cursor:pointer;
      }
    }
    /deep/.ask-component-placeholder{
      background-color: #E4EAFF;
      color: #366aff;
      display: inline-block;
      padding: 2px 5px;
      border-radius: 3px;
      margin-right: 10px;
      margin-bottom: 8px;
    }
    /deep/.ck.ck-editor__editable_inline{
      border-color: #DCDFE6;
    }
  }

  .download{
    margin-top: 16px;
  }

}
.variable{
  display: flex;
  flex-wrap: wrap;
  .variableItem{
    padding: 4px 6px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E4EAFF;
    color: #366aff;
    cursor:pointer;
    font-size: 12px;
  }
  .variableItem:last-child{
    margin-bottom: 0;
  }
}
</style>