<template>
  <div class="docView">
    <div class="docHtml"
         ref="docHtml"
         v-html="html"
         @click="selectDiv"
    >
    </div>
  </div>
</template>

<script>
export default {
  name: "docView",
  data(){
    return{
      colors: ['#E3EBFF', '#FFF5DC', '#FFE8D8','#D6F3EA','#FFF1C3'],
      html:""
    }
  },
  props:{
    html_result:{
      type:String,
      default:""
    },
    split_paragraphs:{
      type:Array,
      default(){
        return []
      }
    },
    fileSuffix:{
      type:String,
      default:""
    }
  },
  methods:{
    selectDiv(e){
      this.$emit('selectDiv',e)
    },
    getHtml(){
      // let url = 'https://guoranwisdom.oss-cn-zhangjiakou.aliyuncs.com/123/2024/12/09/14/18/17/123/%E5%85%AC%E5%8F%B8%E5%91%98%E5%B7%A5%E6%89%8B%E5%86%8C_1733725097.html'
      let url = this.html_result
      this.$http.get(url).then(res => {
        this.html = res.data;
        this.$nextTick(() => {
          this.setColor()
        })
      })
    },
    setColor(){
      this.split_paragraphs.forEach((item,index) => {
        const colorIndex = index % this.colors.length;
        if (item.original_paragraph){
          item.original_paragraph.forEach(items => {
            let dom = document.getElementById(items.paragraph_id);
            if (this.$route.query.fileSuffix === 'txt'){
              const paragraphs = this.$el.querySelectorAll(`[paragraph-id="${items.paragraph_id}"]`);
              paragraphs.forEach(paragraph => {
                paragraph.style.background = this.colors[colorIndex]
              })
            } else if (dom){
              dom.style.background = this.colors[colorIndex];
            }
          })
          let newOriginal = item.original_paragraph.filter(items => !items.type)
          if (newOriginal.length > 0){
            let dom = document.getElementById(newOriginal[newOriginal.length - 1].paragraph_id);
            if (dom){
              dom.style.marginBottom = '8px';
            }
          }
        }
      })
    },
  },
  watch:{
    html_result:{
      handler(){
        if (this.html_result){
          this.getHtml();
        }
      },
      immediate:true
    }
  }
};
</script>

<style scoped lang="less">
.docView{
  width: 100%;
  flex: none;
  box-sizing: border-box;
  div{
    position: relative;
  }
  /deep/img{
    max-width: 100%;
  }
}
</style>