<template>
  <div class="ruleConditions">
    <div class="condition-or-list" v-if="conditionsList.length">
      <div class="condition-or" v-for="(item,index) in conditionsList" :key="index">
        <div class="condition-and">
          <div class="and-item" v-for="(items,indexs) in item" :key="indexs">
            <div class="select-type">
              <el-select v-model="items.variable" filterable  :placeholder="$t('knowledgeManagement.knowledgeTag.ruleCondition.selectInputPlaceholder')" @change="changeVariable(items)">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="$t('knowledgeManagement.knowledgeTag.ruleCondition.' + item.value)"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="select-condition">
              <el-select v-model="items.relation" @change="changeRelation(items)" :placeholder="$t('knowledgeManagement.knowledgeTag.ruleCondition.selectPlaceholder')">
                <el-option
                    v-for="item in conditionOptions[items.variable]"
                    :key="item.value"
                    :label="$t('knowledgeManagement.knowledgeTag.ruleCondition.' + item.value)"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="input-value">
              <el-input v-model="items.value" :placeholder="$t('knowledgeManagement.knowledgeTag.ruleCondition.inputPlaceholder')" v-if="items.variable === 'KNOWLEDGE_NAME' || items.variable === 'KNOWLEDGE_DESC' || items.variable === 'KNOWLEDGE_CONTENT'"></el-input>
              <el-select v-model="items.value"
                         :placeholder="$t('knowledgeManagement.knowledgeTag.ruleCondition.selectPlaceholder')"
                         v-else-if="items.variable === 'KNOWLEDGE_TYPE'"
                         :multiple="items.relation === 'CONTAIN_ANY' || items.relation === 'NOT_CONTAIN_ANY'"
              >
                <el-option
                    v-for="item in KnowledgeTypeList"
                    :key="item.value"
                    :label="$t('knowledgeManagement.knowledgeTag.ruleCondition.' + item.value)"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-date-picker
                  v-model="items.value"
                  v-else-if="items.relation !== 'BETWEEN' && items.relation !== 'NOT_BETWEEN'"
                  type="date"
                  :placeholder="$t('knowledgeManagement.knowledgeTag.ruleCondition.datePlaceholder')">
              </el-date-picker>
              <el-date-picker
                  size="small"
                  v-else
                  v-model="items.value"
                  :key="'daterange'+ indexs + index"
                  type="daterange"
                  :range-separator="$t('knowledgeManagement.knowledgeTag.ruleCondition.range')"
                  :start-placeholder="$t('knowledgeManagement.knowledgeTag.ruleCondition.startDate')"
                  :end-placeholder="$t('knowledgeManagement.knowledgeTag.ruleCondition.endDate')">
              </el-date-picker>
            </div>
            <div class="and-box" v-if="indexs !== item.length - 1">{{$t('knowledgeManagement.knowledgeTag.ruleCondition.and')}}</div>
          </div>
          <div class="operate-and-condition">
            <div class="add-btn" @click="addCondition('and',index)">
              <div class="add-icon">
                <i class="iconfont guoran-a-18-35"></i>
              </div>
              <span>{{$t('knowledgeManagement.knowledgeTag.ruleCondition.addAndCondition')}}</span>
            </div>
            <div class="delete-btn" @click="deleteCondition(index)">
              <i class="iconfont guoran-a-18-13"></i>
              <span>{{$t('knowledgeManagement.knowledgeTag.ruleCondition.delCondition')}}</span>
            </div>
          </div>
        </div>
        <div class="divider" v-if="index !== conditionsList.length - 1">
          <div class="divider-box">{{$t('knowledgeManagement.knowledgeTag.ruleCondition.or')}}</div>
          <el-divider></el-divider>
        </div>
      </div>
    </div>
    <div class="operate-or-condition">
      <div class="add-btn" @click="addCondition('or')">
        <div class="add-icon">
          <i class="iconfont guoran-a-18-35"></i>
        </div>
        <span>{{$t('knowledgeManagement.knowledgeTag.ruleCondition.addOrCondition')}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ruleConditions",
  data(){
    return{
      conditionsList:[],
      options:[
        {
          value:"KNOWLEDGE_NAME",
          label:"知识名称"
        },
        {
          value:"KNOWLEDGE_TYPE",
          label:"知识类型"
        },
        {
          value:"KNOWLEDGE_DESC",
          label:"知识描述"
        },
        {
          value:"KNOWLEDGE_CONTENT",
          label:"知识内容"
        },
        {
          value:"KNOWLEDGE_CREATE",
          label:"知识创建时间"
        },
        {
          value:"KNOWLEDGE_UPDATE",
          label:"知识更新时间"
        },
        // {
        //   value:"KNOWLEDGE_TAKING_EFFECT_TIME",
        //   label:"知识生效时间"
        // }
      ],
      conditionOptions:{
        KNOWLEDGE_TYPE:[
          {
            label:"是",
            value:"IS"
          },
          {
            label:"不是",
            value:"IS_NOT"
          },
          {
            label:"包含任意",
            value:"CONTAIN_ANY"
          },
          {
            label:"不包含任意",
            value:"NOT_CONTAIN_ANY"
          }
        ],
        KNOWLEDGE_NAME:[
          {
            label:"是",
            value:"IS"
          },
          {
            label:"不是",
            value:"IS_NOT"
          },
          {
            label:"包含",
            value:"CONTAIN"
          },
          {
            label:"不包含",
            value:"NOT_CONTAIN"
          }
        ],
        KNOWLEDGE_DESC:[
          {
            label:"是",
            value:"IS"
          },
          {
            label:"不是",
            value:"IS_NOT"
          },
          {
            label:"包含",
            value:"CONTAIN"
          },
          {
            label:"不包含",
            value:"NOT_CONTAIN"
          }
        ],
        KNOWLEDGE_CONTENT:[
          {
            label:"是",
            value:"IS"
          },
          {
            label:"不是",
            value:"IS_NOT"
          },
          {
            label:"包含",
            value:"CONTAIN"
          },
          {
            label:"不包含",
            value:"NOT_CONTAIN"
          }
        ],
        KNOWLEDGE_CREATE:[
          {
            label:"早于",
            value:"BEFORE"
          },
          {
            label:"晚于",
            value:"AFTER"
          },
          {
            label:"介于",
            value:"BETWEEN"
          },
          {
            label:"不介于",
            value:"NOT_BETWEEN"
          }
        ],
        KNOWLEDGE_UPDATE:[
          {
            label:"早于",
            value:"BEFORE"
          },
          {
            label:"晚于",
            value:"AFTER"
          },
          {
            label:"介于",
            value:"BETWEEN"
          },
          {
            label:"不介于",
            value:"NOT_BETWEEN"
          }
        ],
        KNOWLEDGE_TAKING_EFFECT_TIME:[
          {
            label:"是",
            value:"IS"
          },
          {
            label:"不是",
            value:"IS_NOT"
          },
          {
            label:"早于",
            value:"BEFORE"
          },
          {
            label:"晚于",
            value:"AFTER"
          },
          {
            label:"介于",
            value:"BETWEEN"
          },
          {
            label:"不介于",
            value:"NOT_BETWEEN"
          }
        ],
      },
      KnowledgeTypeList:[
        {
          label:"Word",
          value:"WORD"
        },
        {
          label:"Excel",
          value:"EXCEL"
        },
        {
          label:"PPT",
          value:"PPT"
        },
        {
          label:"PDF",
          value:"PDF"
        },
        {
          label:"TXT",
          value:"TXT"
        },
        {
          label:"图片",
          value:"IMAGE"
        },
        {
          label:"视频",
          value:"VIDEO"
        },
        {
          label:"语雀文档",
          value:"YUQUE"
        },
        {
          label:"飞书文档",
          value:"FEISHU"
        },
        {
          label:"其他",
          value:"OTHER"
        }
      ]

    }
  },
  methods:{
    addCondition(type,index){
      if (type === 'and'){
        let obj = {
          variable:"",
          relation:"",
          value:""
        }
        this.conditionsList[index].push(obj)
      } else {
        let obj = [{
          variable:"",
          relation:"",
          value:""
        }]
        this.conditionsList.push(obj);
      }
    },
    deleteCondition(index){
      this.conditionsList.splice(index,1)
    },
    changeVariable(item){
      console.debug('item',item)
      if (item.variable === 'KNOWLEDGE_CREATE' || item.variable === 'KNOWLEDGE_UPDATE'){
        item.relation = "BEFORE";
      } else {
        item.relation = "IS";
      }
      item.value = ""
    },
    changeRelation(item){
      item.value = ""
    },
  }
};
</script>

<style scoped lang="less">
.ruleConditions{

  .condition-or-list{
    background-color: #FAFBFC;
    padding: 20px 14px;
    .condition-or{
      .condition-and{
        margin-left: 28px;
        .and-item{
          display: flex;
          justify-content: flex-start;
          border: 1px solid #e0e6f7;
          border-radius: 5px;
          margin-bottom: 10px;
          position: relative;
          background-color: #ffffff;
          /deep/.el-input__inner{
            border: none;
            padding-right: 0;
          }
          /deep/.el-input__suffix{
            display: none;
          }
          .select-type{
            flex: none;
            width: 200px;
          }
          .select-condition{
            flex: none;
            width: 100px;
          }
          .input-value{
            flex: auto;
            /deep/.el-input__icon{
              display: none;
            }
            /deep/.el-date-editor--daterange.el-input__inner{
              width: 320px;
            }
          }
        }
        .and-box{
          position: absolute;
          left: -14px;
          top: 30px;
          width: 34px;
          height: 34px;
          background: #366AFF;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex: none;
          color: #ffffff;
          z-index: 99;
          font-size: 12px;
        }
        .operate-and-condition{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .add-btn{
            display: flex;
            align-items: center;
            color: #366aff;
            cursor: pointer;
            .add-icon{
              flex: none;
              width: 28px;
              height: 28px;
              background: #366AFF;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 6px;
              color: #ffffff;
            }
          }
          .delete-btn{
            display: flex;
            align-items: center;
            color: #366aff;
            cursor: pointer;
            i{
              margin-right: 6px;
            }
          }
        }
      }
      .divider{
        display: flex;
        align-items: center;
        .divider-box{
          flex: none;
          width: 34px;
          height: 34px;
          background: #04B051;
          border-radius: 50%;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
        }
        /deep/.el-divider--horizontal{
          border-top: 1px dashed #04B051;
          background: 0 0;
        }
      }
    }
  }
  .operate-or-condition{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    .add-btn{
      display: flex;
      align-items: center;
      color: #366aff;
      cursor: pointer;
      .add-icon{
        flex: none;
        width: 34px;
        height: 34px;
        background: #366AFF;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 6px;
        color: #ffffff;
      }
    }
  }
}
</style>