<template>
  <div class="accountManagement">
    <el-dropdown
        trigger="click"
        id="el-dropdown-account"
        ref="messageDrop"
        @visible-change="elDropdownvisibleChange($event)"
        :hide-on-click="true">
      <div :class="['el-dropdown-link']">
        <div class="staff-box">
          <div class="account-list">
            <template v-if="!managePermission.length">
              <span style="color: #dcdfe6">{{$t('common.selectPlaceholder')}}</span>
            </template>
            <template v-else>
              <div class="account-item" v-for="(item,index) in managePermission" :key="item.id">
                <span>{{ item.name }}</span>
                <i class="el-icon-close" @click.stop="clearAccount(item,index)"></i>
              </div>
            </template>
          </div>
          <em
              v-show="!showAccountData"
              class="el-icon-arrow-down"
          ></em>
          <em
              v-show="showAccountData"
              class="el-icon-arrow-up"
          ></em>
        </div>
      </div>
      <el-dropdown-menu
          id="account-dropdown"
          slot="dropdown"
      >
        <el-dropdown-item
            class="account-dropdown-item"
        >
          <div class="accountTree">
            <el-tree
                     :data="accountData"
                     :props="{
                     label: 'name',
                     children: 'children',
                     }"
                     node-key="id"
                     :default-expanded-keys="defaultExpanded"
                     :expand-on-click-node="false"
                     check-on-click-node
                     @node-click="onhandleClick"
                     ref="recommendTree">
              <div class="tree-node" slot-scope="{ data }">
                <div class="tree-node-left">
                  {{ data.name }}
                </div>
                <i class="el-icon-check" v-if="managePermissionIds.indexOf(data.id) !== -1"></i>
              </div>
            </el-tree>
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: "accountManagement",
  data(){
    return{
      accountData:[],
      treeVisable: false,
      managePermission:[],
      managePermissionIds:[],
      defaultExpanded:[],
      showAccountData:false
    }
  },
  methods:{
    getAccountList(){
      let url = "/portal-api/account/find-by?includedPrivileges=PORTAL_ENTRANCE_KNOWLEDGE";
      this.$http.get(url).then(res => {
        console.log(res,'1130');
        if (res.data.code == 0 && res.data.data){
          this.accountData = res.data.data;
        }
      })
    },
    elDropdownvisibleChange(value){
      this.showAccountData = value;
    },
    async staffSelectFocus (event) {
      if(event.stopPropagation) {
        event.stopPropagation()
      }
      this.treeVisable = !this.treeVisable;
      if(this.treeVisable) {
        setTimeout(() => {
          this.$refs.popoverRef.doShow()
        },80)
      }
    },
    onhandleClick(data,node){
      if (this.managePermissionIds.indexOf(data.id) == -1){
        this.managePermissionIds.push(data.id);
        let obj = {
          id:data.id,
          name:data.name,
          type:data.groupId ? 0 : 1
        }
        this.managePermission.push(obj);
      } else {
        this.managePermissionIds = this.managePermissionIds.filter(item => item != data.id);
        this.managePermission = this.managePermission.filter(item => item.id != data.id);
      }
      console.log('data',data,node)
    },
    clearAccount(item,index){
      this.managePermission.splice(index,1);
      this.managePermissionIds.splice(index,1);
    }
  },
  mounted() {
    this.getAccountList();
  }
};
</script>

<style scoped lang="less">
.accountManagement{
  width: 100%;
  .staff-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 38px;
    width: calc(100% - 30px);
    border: 1px solid #dcdfe6;
    border-radius: 5px;
    padding: 0 15px;
    cursor: pointer;
    .account-list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .account-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 26px;
        padding: 0 4px;
        background-color: #E8EDF7;
        border-radius: 5px;
        margin: 4px 8px 4px 0;
        .el-icon-close{
          margin-left: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .el-input {
      width: 100%;
    }
  }
}
#el-dropdown-account{
  width: 100%;
  .el-dropdown-link{
    width: 100%;
  }
}
/deep/.account-dropdown-item{
  width: 500px;
  padding: 0!important;
  .accountTree{
    max-height: 200px;
    overflow-y: scroll;
    .el-tree-node__content{
      height: 38px;
      line-height: 38px;
    }
    .tree-node{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      i{
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
}
/deep/.el-dropdown-menu__item:not(.is-disabled):hover{
  background-color: #FFFFFF;
}
</style>