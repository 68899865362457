<template>
    <div class="file_pre" id="file_pre">
        <template  v-if="withLocationMark">
          <markPdf ref="pdf_view" :withLocationMark="withLocationMark"></markPdf>
        </template>
        <template v-else>
          <template v-if="fileType == 'VIDEO'">
            <video class="video" :src="url" controls></video>
          </template>
          <template v-else-if="fileType == 'HTML'">
              <div class="preview_wechat" id="preview_wechat" style="text-align:left;" v-html="fileText"></div>
          </template>
          <template v-else>
              <iframe
                  class="preview_iframe"
                  :src="previewUrl"
                  style="border:none;"
                  :style="{height:iframeHeight}"
                  :key="key"
              ></iframe>
          </template>
        </template>
        <div class="loading_img" v-show="loading">
          <img src="../assets/images/loading.gif" alt="">
        </div>
    </div>
</template>
<script>

import { requestUrl } from "@/http/requestUrl";
import markPdf from "./markPdf.vue";
import waterMark from "@/utils/common";
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from "uuid";
import { setTimestamp } from '@/assets/js/common'

export default {
    data () {
        return {
          fileText:"",
          loading: false,
          withLocationMark:null,
          iframeHeight:"100%",
          key:uuidv4()
        }
    },
    props: {
        url: {
            type: String,
            required: true,
        },
        fileType: {
          type: String,
          default: '',
        },
        hiddenToolbar:{
          type: String,
          required: false,
        },
        knowledgeItem:{
          type:Object,
          default(){
            return {}
          }
        },
        textWatermarkStr:{
          type: String,
          default: '',
        }
    },
    mounted() {
      // let iframe = document.getElementsByClassName('preview_iframe')[0]
      // if(iframe) {
      //   // this.loading = true
      //   console.log('1111111');
      //   iframe.onload = () => {
      //     this.loading = false
      //   }
      // }
      // if(this.fileType == 'VIDEO') {
      //   this.loading = false
      // }
      if (this.fileType === 'IMAGE'){
        document.getElementById('file_pre').style.height = '600px'
      }
      this.loadIframe();
      window.addEventListener('message',this.handleIframeMessage,false)
    },
    components:{
      markPdf
    },
    computed: {
        ...mapGetters('knowledge', {
          textWatermarkStr: 'getTextWatermarkStr'
        }),
        previewUrl () {
            let url = process.env.VUE_APP_NODE_ENV === 'production' ? 'https://kkfileview.askbot.cn/onlinePreview' :'https://test.open.askbot.cn/kkfileview/onlinePreview'
            url += '?url=' + this.url;
            if (this.fileType == 'PPT') {
              url += '&officePreviewType=pdf'
            }
            if (this.textWatermarkStr){
              url += '&textWatermarkValue=' + this.textWatermarkStr
            }
            // url += '&timestamp=' + new Date().getTime();
            url = setTimestamp(url)
            return url
        },
    },
    methods: {
      getPreviewContent(fileInOssPath){
        this.fileText = "";
        let url = requestUrl.preview.previewContent;
        this.$http.post(url,{
          fileInOssPath: fileInOssPath,
        }).then(res => {
          if (res.data.data){
            this.fileText = res.data.data;
            this.loading = false;
          }
        })
      },
      loadIframe () {
        if (this.fileType == 'VIDEO' || this.fileType == 'WECHAT') {
          this.loading = false
        } else {
          let iframes = document.getElementsByClassName('preview_iframe');
          for (let index = 0; index < iframes.length; index++) {
            iframes[index].onload = () => {
                this.loading = false;
                iframes[index].style.backgroundImage = "none";
            }
          }
          if (this.fileType === 'HTML'){
            setTimeout(() => {
              waterMark.newInitWaterMark('preview_wechat',this.textWatermarkStr)
            },500)
          }
        }
      },
      getInnermostIframe() {
        let iframes = document.querySelectorAll('iframe');
        for (let i = 0; i < iframes.length; i++) {
          let innerIframes = iframes[i].querySelectorAll('iframe');
          if (innerIframes.length > 0) {
            return innerIframes[0];
          }
        }
        return null;
      },
      openMark() {
        this.$refs.pdf_view.openMark()
      },
      handleIframeMessage(res){
        if (res.data.type == 'getViewerHeight'){
          let iframeHeight = this.iframeHeight.replace('px','')
          if (res.data.data && iframeHeight != (res.data.data + 50)){
            this.iframeHeight = res.data.data + 50 + 'px'
          }
        }
      }
    },
    watch:{
      withLocationMark(value) {
        if(value) {
          this.loading = false
        }
      }
    },
    beforeDestroy() {
      window.removeEventListener('message', this.handleIframeMessage);
    }
}
</script>
<style lang="less" scoped>
.file_pre {
    width: 100%;
    //min-height: 400px;
    //height: calc(100% - 40px);
    min-height: 400px;
    position: relative;
    visibility: inherit !important;
    .preview_iframe {
        width: 100%;
        //height: 100%;
        background-image: url('../assets/images/loading.gif');
        background-position: center;
        background-size: 50px;
        background-repeat: no-repeat;
    }
    .video {
        width: 80%;
        height: 50%;
    }
    .preview_wechat {
        width: 100%;
        //height: calc(100% - 32px);
        //height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        overflow-y: auto;
        //overflow-x: scroll;
        padding: 16px;
      background-color: #ffffff;
      border-radius: 5px;
    }
  /deep/img{
    width: 100%;
  }
  /deep/.ne-table{
    width: 100% !important;
  }
}
.loading_img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #FFF;
  img {
    width: 50px;
    height: 50px;
  }
}
</style>