<template>
    <div class="lw-bot">
        <div class="lw-bot-header">
            <div class="lw-bot-header-left">
                <span class="lbhl-icon">
                    <i class="iconfont guoran-askbotdandutubiao2"></i>
                </span>
                <span class="lbhl-name">
                    {{ lbhlName }}
                    <!-- AskBot智能助手 -->
                </span>
            </div>
            <div class="lw-bot-header-right">
                <span @click="closeSearchBot" class="lbhr-close">
                    <i class="iconfont guoran-cuohao"></i>
                </span>
            </div>
        </div>
        <div class="lw-bot-body">
            <div class="no-num-tips" v-show="isShowTips">{{$t("common.noNumTips")}}</div>
            <!-- <messageContent :messageList="messageList" ref="messagecon"></messageContent> -->
            <messageContent
                ref="messagecon"
                :isOhmPc="isOhmPc"
                :_isAskLightning="isAskLightning"
                :sourceOhm="sourceOhm"
                :messageList="messageList"
                :user-id="userId"
                :bot-api-key="currentBot.apiKey"
                :inLoading="inLoading"
                :inLoadingTimeout="inLoadingTimeout"
                :inLoadingTimeoutStr="inLoadingTimeoutStr"
                :media_inLoading="media_inLoading"
                :isShowFastSelect="fastSelectList.length != 0"
                :isShowSendFileContent="isShowSendFileContent"
                :sessionStatus="sessionStatus"
                :isShowHistoryText="isShowHistoryText"
                @onRadioClick="sendRadioMessage"
                @onRadioClick2="sendRadioMessageText"
                @onRadioClickRichBtn="onRadioClickRichBtn"
                @onSatisfactionClick="sendSatisfaction"
                @onImageClick="previewImage"
                @onTemplateInfo="sendTemplateInfo"
                @onConfirmValue="sendConfirmValue"
                @onFormCommit="sendFormCommit"
                @onFeedback="sendFeedback"
                @sendMessage="sendMessageContent"
                @getIframe="getIframe"
                @getHistory="getHistory"
                @imagePreviewFn="imagePreviewFn">
            </messageContent>
            <!-- <iframe height="100%" width="100%" :src="iframeSrc" frameborder="0"></iframe> -->
        </div>
         <!-- 底部：知识管理中引入时的输入框样式 -->
        <div class="bot-footer-box" >
            <div class="input-box">
                <el-input
                    ref="tarea"
                    id="tarea"
                    v-model="testValue"
                    class="uni-input"
                    style="width: 100%"
                    :placeholder="$t('common.placeholder')"
                    @keyup.enter.native=" sendMessageContent('TEXT', testValue, testValue, true)"
                    @confirm="sendTextMessage()"
                ></el-input>
            </div>
            <div class="enter-btn">
            <span class="enter-placeholder">{{$t('common.EnterSearch')}}</span>
            <span class="search-btn" @click=" sendMessageContent('TEXT', testValue, testValue, true)">{{$t('common.search')}}</span>
            </div>
        </div>
    </div>
</template>
<script>
import messageContent from './components/messageContent.vue'
import { requestUrl } from '../../http/requestUrl';
import {  mapGetters } from 'vuex';
import {  adapterMessageForPhoneNum, } from "./js/MessageParse";
import { v4 as uuidv4 } from "uuid";
export default {
    name: "IndexView",
    components:{
        messageContent
    },
    props: {
        
    },
    data() {
        return {
            iframeSrc: "",
            previewItem:{
                visible:false,
                loading:false,
                href:'',
                fileType: "",
                name: "",
                tagIds:""
            },
            canUseAiDiaVisible: false,
            // previewVisible:false,
            // previewLoading:false,
            // previewHref: '',
            // previewFileType: '',
            // knowledgeItem: null,
            botId:"",
            currentBot:{},
            messageList: [],
            testValue:'',
            inLoading: false,
            inLoadingTimeout: false,
            inLoadingTimeoutStr: this.$t('common.holdOnSecond'),
            inLoadingTimer: null,
            timeFlag: true,
            isAskLightning:true,
            sourceOhm:"ohm",
            isOhmPc:true,
            media_inLoading:false,
            isShowSendFileContent: false,
            sessionStatus:{},
            fastSelectList:[],
            isShowPastBtn:false,
            sessionId:'',
            lastMessageId:"",
            historyList:[],
            isShowHistoryText:false, // 是否显示 '加载聊天记录'
            initTop: 0,
            isRefresh: false,
            isLoad: false,
            callback:null,
            isShowTips:false,
            lbhlName: ''
        }
    },
    computed:{
        ...mapGetters('apphandle', {
            isShowTestWindow: 'getIsShowTestWindow'
        }),
    },
    watch:{
        isShowTestWindow(n){
            if(n){
                this.getBotBindInfo();
            }
        },
        // messageList:{
        //     handler(n){
        //         console.log(n,'messageList');
        //     },
        //     deep:true,
        //     immediate:true
        // }
    },
    mounted() {
        this.getBotBindInfo();
        // this.creatOrRemoveMessgaeListener(true);
        this.$refs.tarea.focus();
        // this.refreshData(); // 在顶部时下拉刷新数据
        // this.selectCompanyUseFlow();
        let uid = localStorage.getItem('_uid')
        if(uid == 20281 || uid == 117431) {
            this.lbhlName = this.$t('common.intelligentAssistant');
        } else if(window.location.hostname.includes('ebot.isheely.com') || window.location.hostname.includes('localhost')) {
            this.lbhlName = 'eBot' + this.$t('common.intelligentAssistant');
        } else {
            this.lbhlName = 'AskBot' + this.$t('common.intelligentAssistant');
        }
    },
    beforeDestroy() {
        // this.creatOrRemoveMessgaeListener(false)
    },
    methods: {
        //  查询用户会员信息、流量包、空间容
        selectCompanyUseFlow(){
            this.$http.get(requestUrl.knowledge.selectCompanyUseFlow+ '?mainId=' + sessionStorage.getItem('_mainId')+ '&corpId=' + sessionStorage.getItem('_corpId')).then((res) => {
                if (res.data) {
                    if(res.data.canUsedFlow <= 0){
                        this.isShowTips = true;
                    }
                }
            })
        },
        //#region
        handleCopy() {
            const range = document.createRange();
            range.selectNode(document.getElementById('drag-upload-phone')); //获取复制内容的 id 选择器
            const selection = window.getSelection();  //创建 selection对象
            if (selection.rangeCount > 0) selection.removeAllRanges(); //如果页面已经有选取了的话，会自动删除这个选区，没有选区的话，会把这个选取加入选区
            selection.addRange(range); //将range对象添加到selection选区当中，会高亮文本块
            document.execCommand('copy'); //复制选中的文字到剪贴板
            this.messageFn('success', this.$t('channel.accessInformation.messages.copySuccess'));
            selection.removeRange(range); // 移除选中的元素
        },
        // 关闭机器人搜索展示
        closeSearchBot() {
            this.$emit("showTestWindiow", false);
        },
        // 测试窗-----------
        sendRadioMessage(value, showValue, answerApiKey, redioType) {
            if (redioType == "recommend") {
                this.send("recommend", value, showValue, answerApiKey);
            } else if (redioType == "START_NODE" || redioType == "END_NODE") {
                this.send(redioType, value, showValue, answerApiKey);
            } else if (redioType == "INTELLIGENT") {
                this.send('INTELLIGENT', value, showValue, answerApiKey);
            }else {
                this.send("RADIO", value, showValue, answerApiKey);
            }
        },
        sendRadioMessageText(value, showValue, answerApiKey) {
            this.sendMessageContent("TEXT", value, showValue, answerApiKey);
        },
        onRadioClickRichBtn(value, answerApiKey) {
            this.sendMessageContent("RICH_TEXT_BUTTON", value, false, answerApiKey);
        },
        sendSatisfaction(type, choose, answer, answerRadio, answerApiKey) {
            let value = JSON.stringify({
                input: choose,
                reply_text: answer,
                reply_options: answerRadio,
            });
            this.sendMessageContent(type, value, choose, answerApiKey);
        },
        previewImage(url) {
            console.debug("预览图片地址", url);
        },
        sendTemplateInfo(value) {
            if (value.scope == "LINK") {
                let dom = document.createElement("div");
                if (dom) {
                    dom.innerHTML = value.value;
                    window.open(dom.innerText);
                } else {
                    console.log(
                        "send template info can not create dom, link value",
                        value
                    );
                }
                // var tempwindow = window.open("_blank");
                // tempwindow.location.href = dom.innerText;
            }
            this.sendMessageContent("TEMPLATE_CARD_BUTTON", value, value.name, value.apiKey);
        },
        sendConfirmValue(value) {
            this.sendMessageContent("VALUE_CONFIRM", value, false, value.apiKey);
        },
        sendFormCommit(value) {
            // value.messageId =
            this.sendMessageContent("FORM_SUBMIT", value, false, value.apiKey);
        },
        sendFeedback(value) {
            this.sendMessageContent("FEEDBACK", value, false, value.apiKey);
        },
         //监听iframe中的点击事件
        getIframe() {
            this.showBtn = true;
            let ImContent = document.getElementById("ImContent");
            ImContent.style.display = "block";
            let floatBtn = document.getElementById("float_button");
            if (floatBtn) {
                floatBtn.style.display = "block";
            }
            document.getElementById("iframe").style.display = "none";
        },
        imagePreviewFn(url){
            window.parent.postMessage({emitName:'imagePreview',url:url},'*');
        },
        // 下拉加载历史记录
        refreshData(){
            let oTag = document.querySelector('.lw-bot-body');
            let disY = 0;
            let t = 0;
            let that = this;
            oTag.onmousedown = (ev) =>  {
                console.log('onmousedown');
                let oEvent = ev || event;
                disY = oEvent.clientY;
                oTag.onmousemove = (ev) => {
                    console.log('onmousemove');
                    let oEvent = ev || event;
                    t = oEvent.clientY - disY;
                    if (t > 0 && t > 20) {
                        that.isRefresh = true;
                    }
                };
                oTag.ononmouseout = oTag.onmouseup = () => {
                    console.log('onmouseup');
                    oTag.onmousemove = null;
                    oTag.onmouseup = null;
                    that.isRefresh = false;
                    if (t > 0 && that.initTop == 0) { // that.initTop == 0 必须在顶部时，才能下拉刷新
                        let txt = '';//初始化
                        if(document.selection){ //判断是否是ie浏览器
                            txt =  document.selection.createRange().text;         //ie浏览器
                        } else {
                            txt =  document.getSelection();   //其他浏览器
                        }
                        console.log(txt.toString(),'txt.toString()');
                        if(txt.toString() === ''){
                            console.log('可以加载更多了......');
                            this.$refs.messagecon.dropDownState = 'loading';
                            document.querySelector('.drop').style.paddingTop = '50px';
                            this.getHistory(this.callback,true);
                        }
                    }
                       
                };
                // return false; //chrome、ff、IE9
            };
        },
        // 获取聊天记录
        getHistory(callback,isHistory) {
            if(!this.isShowHistoryText && isHistory){
                console.log('已经没有历史记录了');
                this.callback()
                return false;
            }
            let id = sessionStorage.getItem('_corpId') + '_' + sessionStorage.getItem('_uid');
            let url = '';
            if(this.lastMessageId === ''){
                url = requestUrl.knowledge.corpTestWindowHistory + this.currentBot.apiKey + '/' + id +  "?size=10";
            } else {
                url = requestUrl.knowledge.corpTestWindowHistory + this.currentBot.apiKey + '/' + id +  "?size=10&lastMessageId=" + this.lastMessageId;
            }
            this.$http.get(url).then((res) => {
                if (res.data.code === "0") {
                    let list = [];
                    if(res.data.data.length > 0){
                        let tampLst = [];
                        if(isHistory){
                            res.data.data.length > 0 &&  res.data.data.forEach(item => {
                                if(!item.type){
                                    let content =  JSON.parse(item.content)[0];
                                    item.type = content.type
                                }
                                // 用户消息
                                if(item.source === 'CORP_KNOWLEDGE_TEST'){
                                    if(item.type === 'TEXT'){
                                        tampLst.push({
                                            type: "answer_text",
                                            content: item.content.replace(/\n/g, "<br/>"),
                                            time: item.time,
                                            sessionId: item.sessionId,
                                            keyId: uuidv4() + "99999",
                                            speaker:"user",
                                            id:uuidv4(),
                                            send_status:"sended",
                                        });
                                    } else {
                                        // let copyItem = JSON.parse(JSON.stringify(item))
                                        // copyItem.speaker = "user";
                                        // copyItem.id = uuidv4();
                                        // copyItem.send_status = "sended"
                                        let content = item.content.list[this.randomNum(content.list.length)];
                                        item.content.speaker = "user";
                                        let {messagesCell} = this.handlerMessageStyle(JSON.parse(item.content),item.sessionId,item.time);
                                        console.log(messagesCell,'messagesCell');
                                        tampLst.push(...messagesCell);
                                    }
                                
                                }
                                // 机器人回复的消息
                                if(item.source === 'GUORAN_BOT'){
                                    if(item.type == 'event'){
                                        item.content = JSON.stringify([JSON.parse(item.content)[0]])
                                    } 
                                    let {messagesCell} = this.handlerMessageStyle(JSON.parse(item.content),item.sessionId,item.time)
                                    tampLst.push(...messagesCell);
                                    
                                }

                            })
                        } else {
                            tampLst = res.data.data;
                        }
                    
                        // let arr = tampLst.filter((item) => JSON.stringify(item) != "{}");
                        // if(this.lastMessageId === ''){
                        //     list = arr.splice(0,9)
                        // } else {
                        //     list = arr;
                        // }
                        list = tampLst;
                    } else {
                        list = [];   
                    }
                    if(isHistory){
                            this.messageList = [...list,...this.messageList]
                            if(list.length === 10){
                                this.isShowHistoryText = true;
                                this.lastMessageId = res.data.data[0].id;
                            } else if(list.length < 10){
                                this.isShowHistoryText = false;
                                this.lastMessageId = '';
                            }
                             let container = document.querySelector("#list");
                            // this.scrollHeight = container.scrollHeight;
                            // container.style.overflowY = "hidden";
                            // container.style.overflowY = "auto";
                            setTimeout(() => {
                                container.scrollIntoView({
                                    block: 'center',
                                    behavior: 'smooth'
                                });
                                if (callback && typeof callback === "function") {
                                    this.callback = callback;
                                    callback();
                                }
                            },500)
                            
                    } else {
                        if(list.length === 0) {
                            this.isShowHistoryText = false;
                            this.lastMessageId = '';
                        } else {
                            this.isShowHistoryText = true;
                        }
                    }
                    this.$refs.messagecon.dropDownState = '';
                } else {
                    this.$message.error(this.$t('common.networkException'))
                }
            })
            .catch(() => {
                this.$message.error(this.$t('common.networkException'))
            })
        },
        
       
        /**
         * 获取接入机器人信息
         * @method getBotBindInfo
         * @param {function} callback
         * @return
         */
        getBotBindInfo() {
             this.$http.get(requestUrl.knowledge.selectCorpKnowledgeBotId + '?mainId=' + sessionStorage.getItem('_mainId'))
                .then((res) => {
                    if (res.data.code === "0") {
                         this.currentBot = res.data.data;
                        this.botId = res.data.data.id;
                        this.sessionId = uuidv4().replaceAll('-','');
                        this.sendStartSesseion();
                        this.lastMessageId = '';
                        this.getHistory(false);
                    }
                })
                .catch(() => {
                    this.$message.error(this.$t('common.networkException'))
                })
        },
        sendStartSesseion() {
            let initEntities = {
                params: [
                    {
                        name: "SYS_机器人标识",
                        value: this.currentBot.apiKey,
                        expired: -1,
                    },
                    {
                        name: "SYS_用户标识",
                        value: this.currentBot.userId,
                        expired: -1,
                    },
                    { name: "SYS_消息渠道", value: "测试窗", expired: -1 },
                ],
            };
            this.sendMessageContent(
                "START_SESSION",
                JSON.stringify(initEntities),
                null,
                false
            );
        },
       
        // 发送消息       
        sendMessageContent(type, value, showValue, needShowIntent, other) {
            // if(!this.countInfo.bigModel && this.currentVersion.vipType === 3){
            //     this.messageFn('warning','暂无权限，请联系企业管理员开通权限')
            //     return false
            // }
            this.selectCompanyUseFlow();
            clearTimeout(this.inLoadingTimer);
            console.debug(type, value, showValue, needShowIntent, other,'发送消息');
            if (type == "FAST_SELECT" && JSON.parse(value).action.scope == "LINK") {
                // window.open(JSON.parse(value).action.value);
                return false;
            }
            let questionId = new Date().getTime();
            this.testValue = "";
            this.$refs.tarea.focus();
            if (value != null && value !== "") {
                if(this.messageList.length > 0){
                    clearTimeout(this.inLoadingTimer);
                    this.inLoading = true;
                    this.inLoadingTimer = setTimeout(() => {
                        this.inLoadingTimeout = true;
                        let aIndex = Math.floor(Math.random() * 5);
                        this.inLoadingTimeoutStr = this.$t('loadingMessages')[aIndex]
                    }, 0);
                }
               
                if (showValue != null) {
                    this.messageList.push({
                        content:showValue,
                        id:uuidv4(),
                        sessionId:"",
                        send_status:"sended",
                        speaker:"user",
                        time:undefined,
                        type:"answer_text"
                    });
                   
                    this.$nextTick(() => {
                        let container = document.getElementById("list");
                        container.scrollTop = 10000000;
                    });
                }
                let param = {
                    botId: this.currentBot.id,
                    questionId: questionId,
                    sessionId: this.sessionId,
                    question:value,
                    inputType: type,
                    userInputSource: 'KNOWLEDGE_TEST',
                    corpId:sessionStorage.getItem('_corpId')
                };
                let url = this.requestUrl.dialog.test_v3;
                this.$http.post(url,param).then(res => {
                     if (res.data.code === "0") {
                        let data = res.data.data;
                        let answer = data.processAction;
                        // let intentId = null;
                        if (data.intentId !== -9999) {
                            // intentId = data.intentId;
                        }
                        let questionId = data.questionId;
                        if(this.messageList && this.messageList.length > 0){
                            for (let i = this.messageList.length - 1; i >= 0; i--) {
                                let chat = this.messageList[i];
                                if (chat.questionId != null && chat.questionId == questionId) {
                                    chat.requestCompleted = true;
                                    chat.testWindowDto = data.testWindowDto;
                                    // for (let j = this.intentionList.length - 1; j >= 0; j--) {
                                    //     for (let k = this.intentionList[j].children.length - 1; k >= 0; k--) {
                                    //         for (let l = this.intentionList[j].children[k].children.length - 1; l >= 0;l--) {
                                    //             if (this.intentionList[j].children[k].children[l].id == intentId) {
                                    //                 chat.intentId = [this.intentionList[j].id,this.intentionList[j].children[k].id,this.intentionList[j].children[k].children[l].id];
                                    //             }
                                    //         }
                                    //     }
                                    // }
                                    break;
                                }
                            }
                        }
                        if (answer != null) {
                            // setTimeout(() => {
                                this.addAnswerList(answer,data.sessionId,null);
                                this.inLoading = false;
                            // },4000)
                        } else {
                            this.$nextTick(() => {
                                setTimeout(() => {
                                    this.$refs.messagecon.scrollTo(0, 10000000);
                                }, 500);
                            });
                        }
                    } else {
                        this.inLoading = false;
                        this.inLoadingTimeout = false;
                        this.inLoadingTimeoutStr = '';
                        this.$message.error(res.data.message);
                    }
                }).catch((err) => {
                    console.log(err,'err');
                    this.inLoading = false;
                    this.inLoadingTimeout = false;
                    this.inLoadingTimeoutStr = '';
                    this.$message.error(err.message);
                })
               
            } else {
                this.$message({
                    message: this.$t("common.notAllowSendEmotyMsg"),
                    type: "error",
                    duration: "3000",
                    customClass: "erroeMessage",
                });
            }
            this.uu_scrollToUserLastMsg();
        },
        // 处理各种消息类型
        handlerMessageStyle(contents,sessionId,time){
            // console.log(contents,sessionId,time,'处理各种消息类型');
            let messagesCell = [];
            let isWelcometext = false;
            if (contents == null || contents.length == 0) {
                return;
            }
            for (let i = 0; i < contents.length; i++) {
                let item =  contents[i]; 
                item.user = "bot";
                let tampId = uuidv4();
                let type = item.type;
                let newMessage = {};
                 // 判断当前消息类型是否为欢迎语
                if (item.type == "answer_welcometext") {
                    isWelcometext = true;
                }
                if (type == "action_question") {
                    let question = {
                        type: "answer_text",
                        content: item.content.questions[0],
                        user: "bot",
                        time: time,
                        sessionId: sessionId,
                        keyId: tampId + "99999",
                    };
                    this.messageList.push(question);
                } else if (type == "answer_text" || type == "answer_welcometext" || type == "answer_correct" || type == "TEXT") {
                    let content = item.content.list[this.randomNum(item.content.list.length)];
                    newMessage = {
                        type: "answer_text",
                        content: content.replace(/\n/g, "<br/>"),
                        time: time,
                        sessionId: sessionId,
                        keyId: tampId + "99999",
                    };
                } else if (item.type == "answer_rich_text_pro") {
                    newMessage = {
                        type: "answer_rich_text_pro",
                        content: item.content.html,
                        time: time,
                        sessionId: sessionId,
                        keyId: tampId + "99999",
                    };
                } else if (item.type == "action_send_message_to_bot") {
                    newMessage = {
                        type: "action_send_message_to_bot",
                        content: item.content,
                        time: time,
                        sessionId: sessionId,
                        keyId: tampId + "99999",
                    };
                } else if (item.type == "action_fast_select") {
                   // this.fastSelectList = item.content.list;
                    
                } else if(item.type == "not_found_assets") {
                    item.content = []
                    newMessage = {type: "not_found_assets",}
                    // 转人工的消息类型
                }  else if(item.type == "client-session-change" || item.type == "receiver-offline" ||  item.type == 'action-client-session-change') {
                    newMessage = {
                        type: item.type,
                        payload: item.payload,
                    };
                } else if (type == "answer_image") {
                    newMessage = {
                        type: "answer_image",
                        content:{ url: item.content.url,},
                        user: "bot",
                    };
                    if (item.content.recommend && item.content.recommend != undefined && item.content.recommend.status) {
                        newMessage.recommend = item.content.recommend;
                        newMessage.showRecommend = true;
                    }
                   
                }  else if (type == "answer_video" || type == "video") {
                    newMessage = {
                        type: "answer_video",
                        content:{ url: item.content.url,},
                        user: "bot",
                    };
                    if (item.content.recommend && item.content.recommend != undefined && item.content.recommend.status) {
                        item.recommend = item.content.recommend;
                        item.showRecommend = true;
                    }
                } else if (type == "answer_radio") {
                    newMessage = {
                        type: "answer_radio",
                        content: {
                            description: item.content.description,
                            options: item.content.options,
                            actionId: item.id,
                        },
                        id: item.id,
                        user: "bot",
                    }
                } else if (type === 'answer_doc_knowledge'){
                    newMessage = {
                        type: "answer_doc_knowledge",
                        content:item.content,
                         time: time,
                        sessionId: sessionId,
                        keyId: tampId + "99999",
                    }
                } else {
                    item.keyId = uuidv4() + "_________";
                    newMessage = item;
                }
                if (item.content && item.content.recommend && item.content.recommend != undefined && item.content.recommend.status) {
                    newMessage.recommend = item.content.recommend;
                    let tampReArr = [];
                    newMessage.recommend.list.forEach((item) => {
                        if (item.id != "" && item.text != "") {
                            tampReArr.push(item);
                        }
                    });
                    newMessage.recommend.list = tampReArr;
                    if (tampReArr.length == 0) {
                        newMessage.recommend.status = false;
                    }
                }
                newMessage = adapterMessageForPhoneNum(newMessage);
                if (item.type === "action_form") {
                    // newMessage.id = id;
                }
                messagesCell.push(newMessage);
                
            }
            return {
                messagesCell:messagesCell,
                isWelcometext:isWelcometext
            }
        },
        // 拼接消息类型
        addAnswerList(contents,sessionId,time,isHistory) {
            // console.log('拼接消息类型');
            let {messagesCell,isWelcometext} = this.handlerMessageStyle(contents,sessionId,time)
            // 客服发送消息或者欢迎语
                if (isWelcometext && !isHistory) {
                    this.$nextTick(() => {
                        if (this.timeFlag) {
                            // 2秒之内只能请求一次
                            this.$refs.messagecon.scrollToOldFirst();
                            this.timeFlag = false;
                            setTimeout(() => {
                                this.timeFlag = true;
                            }, 2000);
                        }
                    });
                } 
            clearTimeout(this.inLoadingTimer);
            // if(isHistory){
            //     this.messageList = [...messagesCell,...this.messageList];
            // } else {
                this.messageList = [...this.messageList,...messagesCell];
            // }
            this.inLoading = false;
            this.uu_scrollToUserLastMsg();
        },
        randomNum(minNum,maxNum){
            switch(arguments.length){
                case 1:
                    return parseInt(Math.random()*minNum+1,10)-1;
                case 2:
                    return parseInt(Math.random()*(maxNum-minNum+1)+minNum,10)-1;
                default:
                    return 0;
            }
        },
        // 滚动
        uu_scrollToUserLastMsg() {
            let list = [];
            this.messageList.forEach((item) => {
                if(item.type && item.type !== '' && item.type !== null){
                    list.push(item)
                }
            })
           this.$nextTick(() => {
                let domlist =  document.querySelectorAll('#list .user-detail');
                let lastDom = domlist[domlist.length-1] 
                // 提交表单 提交工单 短工单
            //    setTimeout(() => {
                    if(list[list.length-1] && list[list.length-1].type === 'action_form' ){
                        let lastDomHeight = lastDom.offsetHeight;
                        let containerHeight = document.getElementById("list").offsetHeight;
                        if(lastDomHeight > containerHeight){
                            
                            lastDom.scrollIntoView({
                                behavior: "smooth", // 平滑过渡
                                block: "start", // 上边框与视窗顶部平齐。默认值
                            });
                            document.querySelector('#list').scrollTop = document.querySelector('#list').scrollTop+300;
                        } else {
                            lastDom.scrollIntoView({
                                behavior: "smooth", // 平滑过渡
                                block: "end", // 上边框与视窗顶部平齐。默认值
                            });
                        }
                    } else {
                        if (lastDom){
                            lastDom.scrollIntoView({
                                behavior: "smooth", // 平滑过渡
                                block: "end", // 上边框与视窗顶部平齐。默认值
                            });
                        }
                    }
            //    },500)
            });
        },
        // 滚动到底部
        scrollBottom(block){
            this.$nextTick(() => {
                let domlist =  document.querySelectorAll('#list .user-detail');
                let lastDom = domlist[domlist.length-1] 
               setTimeout(() => {
                    // 发送消息滚动到底部
                    if (lastDom) {
                        lastDom.scrollIntoView({
                            block: block,
                            behavior: 'smooth',
                            inline: "nearest"
                        });
                    }
               },500)
            });
        },
    },
}
</script>
<style lang="less" scoped>
@import "./../../assets/less/common.less";
.drag-upload-phone {
    margin: 50px auto 0;
    width: 240px;
    height: 31px;
    line-height: 31px;
    color: white;
    background: #366AFF;
    border-radius: 15px;
    cursor: pointer;
}
.lw-bot {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: calc(100% - 15px);
    padding-bottom: 15px;

    .lw-bot-header {
        flex-shrink: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 46px;
        background: #FFFFFF;
        border-radius: 5px 5px 0px 0px;

        .lw-bot-header-left {
            flex: auto;
            display: flex;
            justify-content: flex-start;
            margin-left: 12px;
            height: 100%;
            align-items: center;
            color: #366AFF;

            .lbhl-icon {
                i {
                    font-size: 24px;
                }
            }

            .lbhl-name {
                margin-left: 6px;
                font-weight: bold;
            }
        }

        .lw-bot-header-right {
            flex: none;
            width: 30px;

            .lbhr-close {
                cursor: pointer;
                color: #366AFF;

                i {
                    font-size: 18px;
                }
            }
        }
    }

    .lw-bot-body {
        flex: auto;
        width: 100%;
        height: calc(100% - 90px);
        overflow-x: hidden;
        overflow-y: auto;
    }
    .bot-footer-box{
        flex-shrink: 0;
        width: calc(100% - 30px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        margin: 0 10px;
        padding: 0 10px 0 0;
        background-color: #FFFFFF;
        border-radius: 17px;
        /deep/.input-box{
            flex: 1;
            .el-input__inner{
                border: none;
                border-radius: 17px;
            }
        }
        .enter-btn{
            flex: none;
            display: flex;
            align-items: center;
            .enter-placeholder{
                color: #A9B3C6;
                margin-right: 8px;
            }
            .search-btn{
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #366aff;
                color: #FFFFFF;
                border-radius: 12px;
                width: 54px;
                height: 24px;
                font-size: 12px;
                cursor: pointer;
            }
            font-size: 13px;
        }
    }
}
/deep/.pdf-preview-drawer{
  //width: 600px !important;
 width: 1000px!important;
}
.el-drawer__wrapper {
    //width: 700px;
    width: 1000px;
    left: calc(100vw - 700px);
    right: 700px;
    overflow: visible;

    /deep/.el-drawer__body {
        height: 100vh;
    }
}

.pre_title {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 10px;

    .iconfont {
        cursor: pointer;
    }

    .close {
        width: 20px;
        margin-right: 15px;

        i {
            font-size: 12px;
        }
    }

    .title {
        width: calc(100% - 35px);
        text-align: left;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.pre_cont {
    width: 100%;
    height: calc(100vh - 40px);
}
.screenshotPopup{
        .el-dialog{
            background: transparent !important;
           box-shadow: 0 0px 0px #fff;
        }
        .popup-content-screenshot{
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 30px;
            //  width: 300px;
            // height: 50px;
            // width: calc(100vw - 120px);
            background-color: #fff;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            .popup-close-btn{
                position: absolute;
                width: 25px;
                height: 25px;
                background: #FFFFFF;
                box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 21%);
                border-radius: 50%;
                position: absolute;
                right: -8px;
                top: -10px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-size: 16px;
                color: #616161;
            }
            .btn-footer-popup{
                // width: 300px;
                width: 100%;
                margin-top: 20px;
                display: flex;
                justify-content: space-around;
                .cancel-btn,.comfirm-btn{
                    width: 70px;
                    height: 35px;
                    border-radius: 17px;
                    text-align: center;
                    line-height: 35px;
                    font-size: 12px;
                    cursor: pointer;
                }
                .cancel-btn{
                    color: #606266;
                    border: 1px solid #dcdfe6;
                    margin-right: 10px;
                }
                .comfirm-btn{
                    background-color: #366AFF;
                    color: #fff;
                }
                .copy-btn{
                    margin-right: 10px;
                }
            }
        }
}
.no-num-tips{
    font-size:12px;
    color: red;
    background-color:#FFEBEC;
    padding:4px 0;
    text-align: center;
}
</style>