import { render, staticRenderFns } from "./docView.vue?vue&type=template&id=2ebde416&scoped=true"
import script from "./docView.vue?vue&type=script&lang=js"
export * from "./docView.vue?vue&type=script&lang=js"
import style0 from "./docView.vue?vue&type=style&index=0&id=2ebde416&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ebde416",
  null
  
)

export default component.exports