<template>
    <div class="pdfViewPage">
      <!-- 按钮容器 -->
      <div class="button-container" v-if="false">
        <!-- 文件上传 -->
        <label for="file-upload" class="file-upload-label">
          上传 PDF 文件
          <input
            id="file-upload"
            type="file"
            accept="application/pdf"
            @change="handleFileUpload"
            class="file-input"
          />
        </label>
  
<!--        &lt;!&ndash; 跳转按钮（已有高亮） &ndash;&gt;-->
<!--        <button @click="jumpToHighlight" class="action-button">-->
<!--          跳转到第一个高亮区域-->
<!--        </button>-->
<!--  -->
<!--        &lt;!&ndash; 缩放按钮 &ndash;&gt;-->
<!--        <button @click="zoomIn" class="action-button">放大</button>-->
<!--        <button @click="zoomOut" class="action-button">缩小</button>-->
<!--  -->
<!--        &lt;!&ndash; 搜索框和按钮 &ndash;&gt;-->
<!--        <input v-model="searchQuery" placeholder="输入搜索关键词" type="text" />-->
<!--        <button @click="doSearch">搜索</button>-->
<!--  -->
<!--        &lt;!&ndash; 搜索结果导航 &ndash;&gt;-->
<!--        <div v-if="searchTermCount > 0" class="search-nav">-->
<!--          <span>共找到 {{ searchTermCount }} 个匹配</span>-->
<!--          <template v-if="searchTermCount > 1">-->
<!--            <button @click="prevSearchResult">上一个</button>-->
<!--            <button @click="nextSearchResult">下一个</button>-->
<!--          </template>-->
<!--        </div>-->
      </div>
  
      <!-- PDF 渲染容器 -->
      <div class="pdf-container" ref="pdfContainer">
        <div
          v-for="(page, index) in pages"
          :key="index"
          class="page-container"
        >
          <div class="canvas-wrapper" :id="'canvas-wrapper' + index">
            <canvas ref="canvases"></canvas>
            <!-- 在页面渲染中时显示loading -->
            <div v-if="!page.loaded && !page.loading" class="page-loading-overlay">
              <div class="spinner"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
  
<script>
// import { getDocument } from "pdfjs-dist/webpack";
// import pdfWorker from "pdfjs-dist/build/pdf.worker.entry";
const pdfjsLib = window['pdfjsLib']
if(pdfjsLib) {
  pdfjsLib.GlobalWorkerOptions.workerSrc = window['pdfjs-dist/build/pdf.worker']
}
const { TextLayerBuilder } = window['pdfjs-dist/web/pdf_viewer']
import Vue from 'vue';
export default {
  name: "PdfViewer",
  data() {
    return {
      pdfFile: null,
      pdf: null,
      numPages: 0,
      scale: 1, // 初始缩小比例
      renderInProgress: false,
      pages: [], // { pageNumber: number, loaded: boolean, canvas: ref, loading: boolean }
      // 静态高亮（坐标采用顶部为原点）
      highlights: [],
      searchQuery: "",
      searchHighlights: [], // 搜索高亮（PDF文本坐标，自下往上）
      observer: null,
      currentPage: 1, // 当前可视区最接近顶部的页面
      resizeTimeout: null,
      currentSearchIndex: 0,
      searchTermCount: 0,
      loadingTask:null,
      context:null,
      viewport:null,
      colors: ['0, 245, 255', '54, 106, 255', '33, 136, 104','255, 255, 51','0, 255, 127'],
      pageNumber:1
    };
  },
  props:{
    split_paragraphs:{
      type:Array,
      default(){
        return []
      }
    },
    ossPath:{
      type:String,
      default:""
    }
  },
  methods: {
    setHighlights(arr){
      this.highlights = [];
      for (let i =0;i<arr.length;i++){
        for (let j=0;j<arr[i].original_paragraph.length;j++){
          let item = arr[i].original_paragraph[j];
          let obj = {
            position:item.position,
            pageNumber: i+1,
            page:item.page
          }
          this.highlights.push(obj);
        }
      }
    },
    mergedGroups(arr) {
      const groupsMap = {};
      let newArr = arr.filter(item => item.position && item.page)
      newArr.forEach(group => {
        if (!groupsMap[group.pageNumber]) {
          groupsMap[group.pageNumber] = {
            pageNumber: group.pageNumber,
            position: [],
            page:group.page
          };
        }
        groupsMap[group.pageNumber].position = [
          ...groupsMap[group.pageNumber].position,
          ...group.position
        ];
      });
      return Object.values(groupsMap);
    },
    async initPdf() {
      if (!this.pdfFile) return;
      // this.pdf = await pdfjsLib.getDocument(this.pdfFile).promise;
      this.loadingTask = pdfjsLib.getDocument(this.pdfFile);
      await this.loadingTask.promise.then(async pdf => {
        // 获取PDF的第一页
        this.pdf = pdf;
        // this.numPages = pdf._transport.numPages;
        let lazySize = pdf._transport.numPages;
        this.numPages = Math.min(pdf._transport.numPages, lazySize);
        // 初始化页面数据
        this.pages = Array.from({ length: this.numPages }, (_, i) => ({
          pageNumber: i + 1,
          loaded: false,
          canvas: null,
          loading: false,
        }));
        // 清空搜索高亮
        this.searchHighlights = [];
        this.searchTermCount = 0;
        this.currentSearchIndex = 0;
        setTimeout(async () => {
          await this.setCanvasRef();
        },100)
      })

    },
    async loadPdf() {
      if (!this.pdfFile) return;
      await this.initPdf();
      this.initObserver();
    },
    initObserver() {
      if (this.observer) {
        this.observer.disconnect();
      }
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const index = Number(entry.target.getAttribute('data-index'));
              const page = this.pages[index];
              if (!page.loaded && !page.loading) {
                this.renderPage(page.pageNumber);
              }
              this.updateCurrentPage();
            }
          });
        },
        {
          root: this.$refs.pdfContainer,
          rootMargin: '0px 0px 0px 0px',
          threshold: 0.1,
        }
      );
      this.$nextTick(() => {
        setTimeout(() => {
          this.pages.forEach((page, index) => {
            const canvas = page.canvas;
            if (canvas) {
              canvas.setAttribute('data-index', index);
              this.observer.observe(canvas);
            }
          });
        },300)
      });
    },
    async loadPage(number){
      for (let i=0;i<number;i++){
        const page = this.pages[i];
        if (!page.loaded && !page.loading) {
          await this.renderPage(page.pageNumber);
        }
        await this.updateCurrentPage();
      }
    },
    async renderPage(pageNumber) {
      if (!this.pdf || this.renderInProgress) return;
      const pageIndex = pageNumber - 1;
      const pageData = this.pages[pageIndex];
      if (pageData.loaded) return;
      this.pageNumber = pageNumber;
      const page = await this.pdf.getPage(pageNumber);
      let pdfWidth =  page.getViewport({ scale: 1}).width
      const viewport = page.getViewport({ scale: this.calculateScale(pdfWidth) });
      this.viewport = viewport;
      const canvas = pageData.canvas;
      if (!canvas) return;

      const context = canvas.getContext("2d");
      this.context = context;
      const devicePixelRatio = window.devicePixelRatio || 1;
      canvas.width = (viewport.width) * devicePixelRatio;
      canvas.height = viewport.height * devicePixelRatio;
      canvas.style.width = `${viewport.width}px`;
      canvas.style.height = `${viewport.height}px`;
      context.scale(devicePixelRatio, devicePixelRatio);

      this.$set(pageData,'loading',true)
      this.renderInProgress = true;
      const renderTask = page.render({ canvasContext: context, viewport });
      // await renderTask.promise;
      let hasDoc = document.getElementById('textLayer' + pageNumber)
      if (hasDoc){
        hasDoc.parentNode.removeChild(hasDoc);
        // await renderTask.promise;
        // return
      }
      await renderTask.promise.then(() => {
        return page.getTextContent()
      }).then((textContent) => {
        const textDiv = document.createElement('div');
        textDiv.setAttribute('class', 'textLayer');
        textDiv.setAttribute('id', 'textLayer' + pageNumber);
        let textLayer = new TextLayerBuilder({
          textLayerDiv: textDiv,
          pageIndex: pageIndex,
          viewport: viewport,
        });
        textDiv.style.width = `${viewport.width}px`; // 根据 viewport 调整宽度
        textDiv.style.height = `${viewport.height}px`; // 根据 viewport 调整高度（可能需要根据实际文本内容调整）
        textDiv.style.overflow = 'hidden'; // 如果文本内容超出范围，则隐藏它
        textDiv.style.whiteSpace = 'pre-wrap'; // 保留空白符和换行符
        textDiv.style.position = 'absolute'; // 绝对定位以覆盖 canvas
        textDiv.style.top = '0';
        textDiv.style.left = '0';
        textDiv.style.fontSize = '20px'; // 设置字体大小（可能需要根据实际情况调整）
        textDiv.style.color = 'black'; // 设置文本颜色（可能需要根据实际情况调整）
        // textDiv.textContent = textContent; // 设置文本内容
        textLayer.setTextContent(textContent);
        textLayer.render()
        // 将文本 div 添加到覆盖层中
        let pagesDiv = document.getElementById('canvas-wrapper' + pageIndex);
        if (pagesDiv){
          pagesDiv.appendChild(textDiv)
        }
      })
      this.renderInProgress = false;
      this.$set(pageData,'loading',false)
      this.$set(pageData,'loaded',true)

      // 渲染已有高亮区域（静态highlights）
      this.renderHighlights(context, pageNumber, viewport);
      // 渲染搜索高亮区域
      this.renderSearchHighlights(context, pageNumber, viewport);
    },
    calculateScale(pdfWidth) {
      const containerWidth = document.getElementById('subsectionLeft').clientWidth - 50;
      return (containerWidth / pdfWidth) * this.scale;
    },
    async renderHighlights(context, number,viewport) {
      const pageHighlights = this.highlights.filter(item => (item.page === number ))
      let highlights = this.mergedGroups(pageHighlights);
      if (context){
        highlights.forEach(({ position,pageNumber }) => {
          // this.drawHighlight(context, viewport, position, fillStyle, false);
          const groups = [];
          const colorIndex = pageNumber % this.colors.length;
          let fillStyle = 'rgba(' + this.colors[colorIndex] + ',0.1)';
          for (let i = 0; i < position.length; i += 4) {
            groups.push(position.slice(i, i + 4));
          }
          for (let i = 0;i<groups.length;i++){
            this.drawHighlight(context, viewport, groups[i], fillStyle, false);
          }
        });
      }
    },
    renderSearchHighlights(context, pageNumber, viewport) {
      const pageHighlights = this.searchHighlights.filter(h => h.pageNumber === pageNumber);
      pageHighlights.forEach(({ position }) => {
        this.drawHighlight(context, viewport, position, "rgba(255, 255, 0, 0.4)", true);
      });
    },
    drawHighlight(context, viewport, position, fillStyle, fromSearch = false) {
      const [x1, y1, x2, y2] = position;
      const scale = viewport.scale;
      const pageHeight = viewport.height / viewport.scale;

      let canvasX1 = x1 * scale;
      let canvasX2 = x2 * scale;
      let canvasY1, canvasY2;

      if (fromSearch) {
        // 搜索高亮：PDF坐标系（左下原点）
        canvasY1 = (pageHeight - y1) * scale;
        canvasY2 = (pageHeight - y2) * scale;
      } else {
        // 静态 highlights 假设顶部坐标原点不需翻转
        canvasY1 = y1 * scale;
        canvasY2 = y2 * scale;
      }
      // 清除当前路径（如果需要）
      context.beginPath();

      context.fillStyle = fillStyle;
      context.fillRect(
        Math.min(canvasX1, canvasX2),
        Math.min(canvasY1, canvasY2),
        Math.abs(canvasX2 - canvasX1),
        Math.abs(canvasY2 - canvasY1)
      );
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.pdfFile = URL.createObjectURL(file);
        this.clearPages();
        this.loadPdf();
      }
    },
    async setCanvasRef() {
      await this.pages.forEach((page, index) => {
        this.$set(this.pages[index],'canvas',this.$refs.canvases[index])
        // 注意：这里我们直接将 DOM 元素赋值给 page.canvas，
        // 但通常你可能想要对 DOM 元素进行进一步的处理或封装。
      });
    },
    clearPages() {
      this.pages = [];
      if (this.observer) {
        this.observer.disconnect();
      }
    },
    jumpToHighlight() {
      const highlight = this.highlights[0];
      if (!highlight) return;
      this.scrollToHighlight(highlight);
    },
    scrollToHighlight(highlight) {
      if (!highlight) return;
      const { pageNumber, position } = highlight;

      const fromSearch = this.searchHighlights.some(h => h === highlight); 

      this.$nextTick(() => {
        const pageIndex = pageNumber - 1;
        const pageData = this.pages[pageIndex];
        const container = this.$refs.pdfContainer;
        const canvas = pageData.canvas;
        if (!canvas || !container) return;

        const rect = canvas.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        this.pdf.getPage(pageNumber).then(page => {
          let pdfWidth =  page.getViewport({ scale: 1}).width
          const scale = this.calculateScale(pdfWidth);
          const viewport = page.getViewport({ scale });
          const pageHeight = viewport.height / viewport.scale;

          const [x1, y1, x2, y2] = position;

          let targetYPdf, targetXPdf;
          if (fromSearch) {
            // 搜索高亮使用上边界作为参考点
            const highlightTopYPdf = Math.max(y1, y2);
            const highlightLeftXPdf = (x1 + x2) / 2;
            targetYPdf = highlightTopYPdf;
            targetXPdf = highlightLeftXPdf;
          } else {
            // 静态高亮使用中心点
            const highlightCenterYPdf = Number(Number(y1) + Number(y2)) / 2;
            const highlightCenterXPdf = Number(Number(x1) + Number(x2)) / 2;
            targetYPdf = highlightCenterYPdf;
            targetXPdf = highlightCenterXPdf;
          }

          let targetYCanvas, targetXCanvas;
          if (fromSearch) {
            targetYCanvas = (pageHeight - targetYPdf) * scale;
          } else {
            targetYCanvas = targetYPdf * scale;
          }
          targetXCanvas = targetXPdf * scale;

          // 将高亮位置转换为相对于container滚动坐标体系的绝对位置
          // rect.top/left - containerRect.top/left为canvas相对container视口顶部/左侧的偏移
          // 加上container当前的scrollTop/scrollLeft可获得绝对滚动系下的位置
          const highlightAbsoluteY = (rect.top - containerRect.top) + container.scrollTop + targetYCanvas;
          const highlightAbsoluteX = (rect.left - containerRect.left) + container.scrollLeft + targetXCanvas;

          const topOffset = container.clientHeight / 2;
          const leftOffset = container.clientWidth / 2;

          const scrollTop = Math.max(0, highlightAbsoluteY - topOffset);
          const scrollLeft = Math.max(0, highlightAbsoluteX - leftOffset);

          container.scrollTo({
            top: scrollTop,
            left: scrollLeft,
            behavior: "smooth",
          });
        });
      });
    },
    zoomIn() {
      this.updateCurrentPage();
      if (this.renderInProgress) return;
      const container = this.$refs.pdfContainer;
      const currentScroll = container.scrollTop;
      this.scale += 0.1;
      this.reloadAllPages(currentScroll);
    },
    zoomOut() {
      this.updateCurrentPage();
      if (this.renderInProgress) return;
      const container = this.$refs.pdfContainer;
      const currentScroll = container.scrollTop;
      this.scale = Math.max(this.scale - 0.1, 0.1);
      this.reloadAllPages(currentScroll);
    },
    reloadAllPages(scrollPosition) {
      const pages = Vue.observable(this.pages)
      pages.forEach((p) => {
        p.loaded = false;
        this.$set(p,'loading',false)
        if (p.canvas) {
          const ctx = p.canvas.getContext('2d');
          ctx.clearRect(0,0,p.canvas.width,p.canvas.height);
        }
      });

      const container = this.$refs.pdfContainer;
      this.$nextTick(() => {
        container.scrollTop = scrollPosition;
        this.initObserver();
        container.scrollTop = container.scrollTop + 1;
        container.scrollTop = container.scrollTop - 1;
      });
    },
    updateCurrentPage() {
      const container = this.$refs.pdfContainer;
      if (!container) return;
      let closestPage = 1;
      let closestDistance = Infinity;
      this.pages.forEach((p) => {
        if (p.canvas) {
          const rect = p.canvas.getBoundingClientRect();
          const containerRect = container.getBoundingClientRect();
          const distance = Math.abs(rect.top - containerRect.top);
          if (distance < closestDistance) {
            closestDistance = distance;
            closestPage = p.pageNumber;
          }
        }
      });
      this.currentPage = closestPage;
    },
    async doSearch() {
      if (!this.pdf || !this.searchQuery) return;
      if (this.renderInProgress) return;

      this.searchHighlights = [];
      const numPages = this.numPages;
      const searchTerm = this.searchQuery;

      for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
        const page = await this.pdf.getPage(pageNumber);
        const textContent = await page.getTextContent();
        const items = textContent.items;
        let fullText = "";
        let offsetPositions = [];
        let currentPos = 0;
        items.forEach((item) => {
          offsetPositions.push({
            str: item.str,
            start: currentPos,
            end: currentPos + item.str.length,
            transform: item.transform,
            width: item.width,
          });
          currentPos += item.str.length;
          fullText += item.str;
        });

        let startIndex = 0;
        while ((startIndex = fullText.indexOf(searchTerm, startIndex)) !== -1) {
          const endIndex = startIndex + searchTerm.length;
          const matchedItems = offsetPositions.filter(
            (op) => op.start <= startIndex && op.end >= endIndex
          );

          if (matchedItems.length > 0) {
            const matchItem = matchedItems[0];
            const substringLength = searchTerm.length;
            const avgCharWidth = matchItem.width / matchItem.str.length;

            const substringOffset = startIndex - matchItem.start;

            const lineHeight = Math.abs(matchItem.transform[3]);
            const y1 = matchItem.transform[5];
            const y2 = y1 + lineHeight;

            const xStart =
              matchItem.transform[4] + substringOffset * avgCharWidth;
            const xEnd = xStart + substringLength * avgCharWidth;

            this.searchHighlights.push({
              pageNumber,
              position: [xStart, y1, xEnd, y2],
            });
          }
          startIndex = endIndex;
        }
      }

      this.searchTermCount = this.searchHighlights.length;
      this.currentSearchIndex = 0;

      const container = this.$refs.pdfContainer;
      const currentScroll = container.scrollTop;
      this.pages.forEach((p) => {
        p.loaded = false;
        this.$set(p,'loading',false)
        if (p.canvas) {
          const ctx = p.canvas.getContext("2d");
          ctx.clearRect(0, 0, p.canvas.width, p.canvas.height);
        }
      });
      this.$nextTick(() => {
        container.scrollTop = currentScroll;
        this.initObserver();
        container.scrollTop = container.scrollTop + 1;
        container.scrollTop = container.scrollTop - 1;

        if (this.searchTermCount > 0) {
          this.scrollToHighlight(this.searchHighlights[0]);
        }
      });
    },
    prevSearchResult() {
      if (this.searchTermCount <= 1) return;
      this.currentSearchIndex = (this.currentSearchIndex - 1 + this.searchTermCount) % this.searchTermCount;
      this.scrollToHighlight(this.searchHighlights[this.currentSearchIndex]);
    },
    nextSearchResult() {
      if (this.searchTermCount <= 1) return;
      this.currentSearchIndex = (this.currentSearchIndex + 1) % this.searchTermCount;
      this.scrollToHighlight(this.searchHighlights[this.currentSearchIndex]);
    },
    onResize() {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        if (this.renderInProgress) return;
        const container = this.$refs.pdfContainer;
        const currentScroll = container.scrollTop;
        const pages = Vue.observable(this.pages)
        pages.forEach((p) => {
          p.loaded = false;
          this.$set(p,'loading',false)
          if (p.canvas) {
            const ctx = p.canvas.getContext('2d');
            ctx.clearRect(0,0,p.canvas.width,p.canvas.height);
          }
        });
        this.$nextTick(() => {
          container.scrollTop = currentScroll;
          this.initObserver();
          container.scrollTop = container.scrollTop + 1;
          container.scrollTop = container.scrollTop - 1;
        });
      }, 300);
    },
  },
  async mounted() {
    window.addEventListener("resize", this.onResize);
    this.pdfFile = this.ossPath;
    // this.clearPages();
    // this.loadPdf();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  watch:{
    split_paragraphs:{
      async handler(val){
        this.highlights = [];
        if (val.length > 0){
          console.log('val',val)
          await this.setHighlights(val);
        }
        this.clearPages();
        this.loadPdf();
      },
      immediate:true,
      deep:true
    }
  }
};
</script>

<style lang="less" scoped>
.pdfViewPage{
  width: 100%;
}
.pdf-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 auto;
  position: relative;
  border-radius: 10px;
  background: #ffffff;
  width: 100%;
  .textLayer{
    span{
      position: absolute;
      color: transparent;
      white-space:pre;
      cursor: text;
      transform-origin:0,0
    }
  }
}

.page-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.canvas-wrapper {
  position: relative;
}

/* 在loading时覆盖canvas显示加载动画 */
.page-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #555;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

canvas {
  border: 1px solid #ddd;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px 0;
  justify-content: center;
}

.search-nav {
  display: flex;
  align-items: center;
  gap: 10px;
}

input[type="file"],
button,
input[type="text"] {
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f5f5f5;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

button:hover,
input[type="file"]:hover,
input[type="text"]:hover {
  background-color: #e0e0e0;
}

button:active,
input[type="file"]:active,
input[type="text"]:active {
  background-color: #d0d0d0;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
